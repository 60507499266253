<template>
    <div style="margin:18vh 0">
      <!-- 导航栏 -->
      <div>
        <b-navbar type="dark" variant="info" fixed="top">
          <b-navbar-brand href="./#/filmTranslation">电影翻译库</b-navbar-brand>
        </b-navbar>
      </div>
      <div align="center">
        <a style="font-size:2em; font-weight:bold;">（致谢名单）</a>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {

      }
    },
    methods: {

    }
  }
  </script>
  
