<template>
  <div>
    <el-slider v-model="xScaleChange" vertical height="200px" style="position: fixed; margin-left:20px"></el-slider>
    <div class="videos" style="height: 660px; padding-left: 3vw">
      <svg id="videosByTime" height="100%" width="100%" />
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";
import axios from "axios";
export default {
  data() {
    return {
      work: [],
      num: 0,
      tableData: [],
      xScaleChange: 0,
    }
  },
  watch: {
    xScaleChange(newval, old) {
      this.drawD3();
    }
  },
  methods: {
    toVideoIndex() {
      this.$router.push({
        path: '/video_index'
      })
    },
    getInfo() {
      axios.get("https://api.film.yingshinet.com/film1_line")
        .then((res) => {
          this.work = res.data.pictures;
          this.num = res.data.count;

          for (var i = 0; i < this.num; i++) {
            this.tableData[i] = {};
            this.tableData[i].no = this.work[i].id;
            this.tableData[i].name = this.work[i].name;
            this.tableData[i].pic = this.work[i].filmImageFile;
            this.tableData[i].director = this.work[i].director_original;
            this.tableData[i].year = this.work[i].release_time_original;
            if (this.work[i].douban_rank === null) {
              this.tableData[i].rank = 0
            } else {
              this.tableData[i].rank = this.work[i].douban_rank;
            }
          }

          this.$set(this.tableData);
          this.initVideoCircle()
        })
        .catch((error) => {
          console.error(error);
        });
    },

    initVideoCircle() {
      this.drawD3()
      this.$nextTick(function () {
        document.querySelectorAll(".img").forEach((item) => {
          item.addEventListener("click", () => {
            console.log(item)
            let id = item.id;
            this.$store.commit('change_current_film_page', '1')
            this.$store.commit('change_current_active_index', "1")
            this.$router.push({ path: "/intro", query: { film: id } });
          });
        });
      });
    },

    drawD3() {

      // 滑轮滑动改变xScale
      let bodywidth = document.body.clientWidth
      let ww = 2 * this.xScaleChange + 97
      let width = bodywidth / 100 * ww;
      let leftw = bodywidth / 100 * ww * 0.01;
      let rightw = bodywidth / 100 * ww * 0.92;

      //提示框部分
      let tooltip = d3.select("body")
        .append("div")
        .attr("class", "tips")
        .style("opacity", 0.0)
        .style("position", "absolute")
        .style("width", 20)
        .style("height", "auto")
        .style("border-style", "solid")
        .style("border-width", "1px")
        .style("background-color", "white")
        .style("border-radius", "5px")
        .style("font-family", "simsun")
        .style("font-size", "14px")
        .style("text-align", "center")
        .style("line-height", "1.3em")

      let height = $(".videos").height() * 0.98
      let svg = d3.select("#videosByTime")
        .attr("width", width)
        .attr("height", height);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();
      let xScale = d3.scaleLinear().range([leftw, rightw]).domain([1980, 2022])
      // 豆瓣评分作为y轴的比例尺
      let yScale = d3.scalePow().exponent(4)
      // x轴                                 
      let xAxis = svg.append('g')
        .attr('class', 'xAxis')
        .attr('transform', `translate(0, ${height - 20})`)
        .call(d3.axisBottom(xScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`))
      // 竖线
      let lines = svg.append('g')
        .attr("class", "lines")
        .selectAll(".lines")
        .data(this.tableData)
        .enter()
        .append("line")
        .attr("x1", function (d, i) {
          return xScale(d.year);
        })
        .attr("y1", height - 20)
        .attr("x2", function (d, i) {
          return xScale(d.year);
        })
        .attr("y2", function (d, i) {
          return height - (yScale(d.rank) * 0.066 + 46)
        })
        .attr("stroke", "black")
        .attr("stroke-width", 0.6)
        .attr("opacity", 0.8);
      let img_h = 60
      let img_w = 60
      let radius = 23

      let circles = svg.selectAll(".img")
        .data(this.tableData)
        .enter()
        .append("circle")
        .attr("class", "img")
        .attr("id", function (d) {
          return d.no
        })
        .attr("r", radius)
        .attr("cx", function (d, i) {
          return xScale(d.year);
        })
        .attr("cy", function (d) {
          return height - (yScale(d.rank) * 0.066 + 46);
        })
        .style("stroke", "DarkGray")
        .style("stroke-width", "1.0px")
        .attr("fill", function (d, i) {
          //创建圆形图片
          var defs = svg.append("defs").attr("id", "imgdefs");
          var catpattern = defs.append("pattern")
            .attr("id", "catpattern" + i)
            .attr("height", 1)
            .attr("width", 1);
          catpattern.append("image")
            .attr("x", - (img_w / 2 - radius + 5.8))
            .attr("y", - (img_h / 2 - radius + 3.5))
            .attr("width", img_w + 11)
            .attr("height", img_h + 6)
            .attr("xlink:href", d.pic);
          return "url(#catpattern" + i + ")";
        })
        .on("mouseover", function (d, i) {
          tooltip.html(d.name + "</br>" + "上映时间：" + d.year + "年" + "</br>" + "导演：" + d.director + "</br>" + "豆瓣评分：" + (d.rank))
            .style("left", d3.event.pageX + "px")
            .style("top", (d3.event.pageY + 20) + "px")
            .style("opacity", 1.0);
          d3.select(this)
            .style("cursor", "pointer");
        })
        .on("mousemove", function (d) {
          tooltip.style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY + 20) + "px");
        })
        .on("mouseout", function (d) {
          tooltip.style("opacity", 0.0);
        })
        .on("click", function (d) {
          tooltip.style("opacity", 0.0);
        })
    }
  },

  mounted() {
    this.getInfo()
  }
}

</script>

<style scoped>
::v-deep .el-slider__bar {
  background-color: #f0755c !important;
}
::v-deep .el-slider__button {
  border: 2px solid #f0755c !important;
}
</style>