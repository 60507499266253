<template>
  <div style="margin:18vh 0">
    <!-- 导航栏 -->
    <div>
      <b-navbar type="dark" variant="info" fixed="top">
        <b-navbar-brand href="./#/filmTranslation">电影翻译库</b-navbar-brand>
      </b-navbar>
    </div>
    <div align="center">
      <a href="./#/">
        <img src="../assets/top2.jpg" />
      </a>
    </div>
    <br />
    <div class="container" style="width:40%">
      <b-form-group class="mb-0" label="用户名：" label-for="input-formatter" description="管理员权限">
        <b-form-input
          id="login-name"
          v-model="userName"
          placeholder="请输入用户名"
          :formatter="formatter"
        ></b-form-input>
      </b-form-group>
      <br />
      <b-form-group class="mb-0" label="密码：" label-for="login-password" description="管理员权限">
        <b-form-input
          id="input-lazy"
          type="password"
          v-model="passWord"
          placeholder="请输入密码"
          lazy-formatter
          :formatter="formatter"
        ></b-form-input>
      </b-form-group>
      <div align="right">
        <b-button type="submit" variant="info" @click="login">登录</b-button>
      </div>  
      <div align="center" style="margin-top: 20px;">
        <a>暂时不提供注册功能，项目结题后数据开源，谢谢关注 :）</a>
      </div>      
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      text1: '',
      passWord: null,
      userName: '',
      status: '',
    }
  },
  methods: {
    formatter(value) {
      return value.toLowerCase()
    },
    login() {
      const path = "https://api.film.yingshinet.com/login";
      axios.post(path, {
        "name": this.userName,
        "password": this.passWord
      })
        .then(res => {
          if (res.data.msg == 'Success') {
            sessionStorage.setItem("token", 'true')
            this.$message({
              message: '登陆成功！',
              type: 'success'
            });
            this.$store.commit('change_state_in')
            this.$store.commit('set_current_user', this.userName)
            this.$router.go(-1)
          } else {
            this.$message({
              message: '登陆失败，请输入用户名或密码！',
              type: 'warning'
            });
          }
        })
        .catch((error) => {
          if (error.response.data.msg == 'Invalid username or password.') {
            this.$message({
              message: '登陆失败，请检查用户名或密码输入是否正确！',
              type: 'warning'
            });
          } else if (error.response.data.msg == this.userName + ' has not been confirmed.') {
            this.$message({
              message: '登陆失败，用户尚未审核通过！',
              type: 'warning'
            });
          }
        });
    },
  }
}
</script>
