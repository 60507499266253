import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import ElementUI from 'element-ui'
import '../node_modules/element-ui/lib/theme-chalk/index.css'
import store from './store'
import '@/assets/fonts/fonts.css'

// 引入百度地图（不用再到html中导入）
import VueBMap, {initBMapApiLoader} from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'
Vue.use(VueBMap)
initBMapApiLoader({
  ak: 'D3yXDfIAn0WvyVUfEsdzGK1Ao1CgeCiG',
})

Vue.prototype.$axios = axios;
Vue.use(BootstrapVue, axios);
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (sessionStorage.getItem("token") == 'true') { // 判断本地是否存在token
      next()
      console.log("true-main")
    }
    else {//本地不存在token
      // 未登录,跳转到登陆页面
      console.log("false-main")
      next({
        path: "/"
      })
    }
  }
  else {//不需要登录权限
    console.log("no need-main")
    next();
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');


