<template style="
      text-align: center;"
      >
  <div
    class="container-border"
    style="height: 30vh; width: 100%; margin: 0 auto;text-align:center ;font-size:16px"
  >
    <!-- <h5>
      电影来源分布
    </h5> -->
    <svg
      id="barchart"
      class="barchart-border"
    ></svg>

  </div>
</template>

<script>
import axios from "axios";
import * as d3 from "d3";
import * as $ from "jQuery";
import { log } from 'console';

export default {
  name: "Barchart",
  data () {
    return {
      send_books: "",
      //barchart数据
      chartdata: {},
    };
  },
  methods: {
    //  绘制直方图
    preDrawBarChart (chartdata) {
      let country_original = []
      let country_original_count = [];
      let country_original_top10 = []
      let country_original_count_top10 = [];
      let othernumber = 0
      //加载并筛选电影数据,country_original为电影产地列表，country_original_count为数量列表
      for (let i = 0; i < chartdata.length; i++) {
        if (i < 9) {
          country_original_top10.push(chartdata.data[i].name)
          country_original_count_top10.push(chartdata.data[i].value)
        } else {
          othernumber += chartdata.data[i].value
        }
        country_original.push(chartdata.data[i].name)
        country_original_count.push(chartdata.data[i].value)
      }
      country_original_top10.push("其他")
      country_original_count_top10.push(othernumber)


      this.DrawBarChart(country_original_top10, country_original_count_top10)
      // this.DrawBarChart(country_original, country_original_count)
    },
    DrawBarChart (country_original, country_original_count) {
      var that = this;
      // 每次绘图时清空svg
      let svg = d3.select("#barchart").selectAll("*").remove();
      //创建序数比例尺和包括10中颜色的输出范围
      var color = d3.scaleOrdinal(d3.schemeCategory10);
      //设定svg及其父元素尺寸大小并且为响应式
      let w = $(".container-border").width();
      let h = $(".container-border").height();
      // w = $("body").width() * 0.65;
      // h = country_original_count.length * 30;
      // 设置svg中图像的偏移位置
      const margin = { top: 20, right: 50, bottom: 20, left: 0 };
      w = w - margin.left - margin.right;
      h = h - margin.top - margin.bottom;
      //留白 边距
      var padding = { top: 0, right: 0, bottom: 0, left: 120 };
      //创建svg设定尺寸与偏移
      svg = d3
        .select("#barchart")
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // 标题
      // svg
      //   .append("text")
      //   .attr("x", (w * 1) / 2)
      //   .attr("y", (h * 1) / 56)
      //   .attr("text-anchor", "middle")
      //   .attr("font-size", 20)
      //   .text("电影来源分布");

      // var dsum = d3.sum(country_original_count); //获得数据之和

      //创建直方图
      var xAxisWidth = w * 0.82;
      var yAxisWidth = h * 1;
      var yScale = d3.scaleBand().domain(country_original).rangeRound([0, yAxisWidth]);
      var xScale = d3
        .scalePow()
        .exponent(0.3)
        .domain([0, d3.max(country_original_count)])
        .range([0, xAxisWidth]);
      var xAxis = d3.axisTop(xScale);
      var yAxis = d3.axisLeft(yScale);

      svg
        .append("g")
        .attr("class", "X axis")
        .attr(
          "transform",
          "translate(" + padding.left + "," + padding.top + ")"
        )
        .call(xAxis);

      svg
        .append("g")
        .attr("class", "Y axis")
        .attr(
          "transform",
          "translate(" + padding.left + "," + padding.top + ")"
        )
        .call(yAxis)
        .selectAll("text")
        .style("font-size", "16px");

      var rect = svg
        .selectAll("rect")
        .data(country_original_count)
        .enter()
        .append("rect")
        .attr("id", function (d, i) {
          return "rect-" + i;
        })
        .attr("fill", function (d, i) {
          return color(i);
        })
        .attr("x", padding.left + 1)
        .attr("y", function (d, i) {
          return padding.top + yScale(country_original[i]) + yScale.bandwidth() * 0.1;
        })
        .attr("height", yScale.bandwidth() * 0.8)
        .attr("width", function (d, i) {
          return xScale(country_original_count[i]);
        })
        .on("click", function (d, i) {
          // console.log(`${country_original[i]}`);
          const payload = {
            country_produce: country_original[i],
          };
          that.send_books = country_original[i];
          that.getQueryBooks(payload);

          // console.log(that.send_books)
          // console.log("getQueryBooks", that.send_books);
        })
        .on("mouseover", function (d, i) {
          d3.select(this).attr("fill", d3.rgb(color(i)).brighter());
          d3.select("#arc-" + i).attr("fill", d3.rgb(color(i)).brighter());
          svg
            .append("text")
            .attr("id", "info")
            .attr("x", (w * 4) / 5)
            .attr("y", (h * 2) / 3 - 13)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .text(country_original[i]);

          svg
            .append("text")
            .attr("id", "value")
            .attr("x", (w * 4) / 5)
            .attr("y", (h * 2) / 3 + 10)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .text(`${country_original_count[i]}部`);
        })
        .on("mouseout", function (d, i) {
          d3.select(this).attr("fill", color(i));
          d3.select("#arc-" + i).attr("fill", color(i));
          d3.select("#num").remove();
          d3.select("#info").remove();
          d3.select("#value").remove();
        });

      this.DrawPieChart(country_original, country_original_count, w, h)
    },
    DrawPieChart (country_original, country_original_count, w, h) {
      var that = this;
      let svg = d3.select("#barchart")
      //创建序数比例尺和包括10中颜色的输出范围
      var color = d3.scaleOrdinal(d3.schemeCategory10);
      const margin = { top: 20, right: 50, bottom: 20, left: 0 };



      //创建svg设定尺寸与偏移
      svg = d3
        .select("#barchart")
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // 标题
      // svg
      //   .append("text")
      //   .attr("x", (w * 1) / 2)
      //   .attr("y", (h * 1) / 56)
      //   .attr("text-anchor", "middle")
      //   .attr("font-size", 20)
      //   .text("电影来源分布");

      var dsum = d3.sum(country_original_count); //获得数据之和
      //创建饼图
      var pie = d3.pie().value(function (d, i) {
        return country_original_count[i];
      });

      // 饼图尺寸
      var width = w * 0.15;
      var height = width;
      var out = 0;
      var outerRadius = width / 1.85 + out;
      var innerRadius = width / 3.5;

      //用svg的path绘制弧形的内置方法
      var arc = d3
        .arc() //设置弧度的内外径，等待传入的数据生成弧度
        .outerRadius(outerRadius)
        .innerRadius(innerRadius);

      // Another arc that won't be drawn. Just for labels positioning
      var outerArc = d3
        .arc()
        .innerRadius(outerRadius * 1.05)
        .outerRadius(outerRadius * 1.05);

      var radius = outerRadius * 1.2;


      //准备分组,把每个分组移到图表中心
      var arcs = svg
        .selectAll("g")
        .data(pie(country_original_count))
        .enter()
        .append("g")
        .attr(
          "transform",
          "translate(" + (w * 4) / 5 + "," + (h * 2) / 3 + ")"
        ); //translate(a,b)a表示横坐标起点，b表示纵坐标起点

      //为组中每个元素绘制弧形路路径
      arcs
        .append("path") //每个g元素都追加一个path元素用绑定到这个g的数据d生成路径信息
        .attr("id", function (d, i) {
          return "arc-" + i;
        })
        .attr("fill", function (d, i) {
          //填充颜色
          return color(i);
        })
        .attr("d", arc) //将角度转为弧度（d3使用弧度绘制
        .on("mouseover", function (d, i) {

          out = 50;
          d3.select(this).attr("fill", d3.rgb(color(i)).brighter());
          d3.select("#arc-" + i).attr("fill", d3.rgb(color(i)).brighter());
          svg
            .append("text")
            .attr("id", "info")
            .attr("x", (w * 4) / 5)
            .attr("y", (h * 2) / 3 - 13)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .text(country_original[i])


          svg
            .append("text")
            .attr("id", "value")
            .attr("x", (w * 4) / 5)
            .attr("y", (h * 2) / 3 + 10)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .text("(" + ((country_original_count[i] / dsum) * 100).toPrecision(5) + "%)");
        })
        .on("mouseout", function (d, i) {
          d3.select(this).attr("fill", color(i));
          d3.select("#arc-" + i).attr("fill", color(i));
          d3.select("#info").remove();
          d3.select("#value").remove();
        })
        .on("click", function (d, i) {
          const payload = {
            country_produce: country_original[i],
          };
          that.send_books = country_original[i];
          that.getQueryBooks(payload);

        });

      svg
        .selectAll("allPolylines")
        .data(pie(country_original_count))
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          if (d.index < 3) {

            var posA = arc.centroid(d); // line insertion in the slice
            var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
            var posC = outerArc.centroid(d); // Label position = almost the same as posB
            var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
            posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
            posA[0] += (w * 4) / 5;
            posA[1] += (h * 2) / 3;
            posB[0] += (w * 4) / 5;
            posB[1] += (h * 2) / 3;
            posC[0] += (w * 4) / 5;
            posC[1] += (h * 2) / 3;
            posA[0] = (posA[0] + posB[0]) * 0.5;
            posA[1] = (posA[1] + posB[1]) * 0.5;
            return [posA, posB, posC];
          }
        })
        .on("click", function (d, i) {
          const payload = {
            country_produce: country_original[i],
          };
          that.send_books = country_original[i];
          that.getQueryBooks(payload);

        });

      svg
        .selectAll("allLabels")
        .data(pie(country_original))
        .enter()
        .append("text")
        .text(function (d) {
          if (d.index < 3) {
            return (
              d.data + ":" + ((country_original_count[d.index] / dsum) * 100).toPrecision(5) + "%"
            );
          }
        })
        .attr("transform", function (d) {
          var pos = outerArc.centroid(d);
          var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
          pos[0] += (w * 4) / 5;
          pos[1] += (h * 2) / 3;

          return "translate(" + pos + ")";
        })
        .style("text-anchor", function (d) {
          var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        })
        .on("click", function (d, i) {
          const payload = {
            country_produce: country_original[i],
          };
          that.send_books = country_original[i];
          that.getQueryBooks(payload);

        });
    },
    getQueryBooks (payload) {
      // console.log("getQueryBooks", this.send_books);
      this.$parent.$parent.select_input = this.send_books
      this.$parent.$parent.film_select()
      // const path = "https://api.film.yingshinet.com/filmsquery1";
      // axios
      //   .post(path, payload)
      //   .then((res) => {
      //     this.send_books = res.data.books;
      //     // console.log(res)

      //     if (this.send_books.length != 0) {
      //       this.$router.push({
      //         name: "FilmTranslation",
      //         params: { books: JSON.stringify(this.send_books) },
      //       });

      //     } else {
      //       alert("没有对应的内容！");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
    formatbarchartData (data) {//格式化直方图数据
      return data //排序
        .map(({ country, count }) => {
          return { name: country, value: count };
        })
        .sort((a, b) => d3.descending(a.value, b.value));
    },
  },
  watch: {
    // 监听传递过来值的变化，如果有变化就重新初始化chart,axios有异步会自动初始调用
    chartdata (newvalue, oldValue) {
      this.preDrawBarChart(newvalue)
    }
  },
  created () {
    // 获取直方图和饼图的数据
    this.$axios.get("https://api.film.yingshinet.com/country1").then((res) => {
      const apidata = res.data["country1"];
      const akeys = []
      const bvalues = []
      const countryCount = []

      for (const key in apidata) {
        // console.log(key);
        // if (key != "空" && key != "？") {
        //去掉空的影响
        akeys.push(key);
        bvalues.push(apidata[key]);
        // tmp = tmp - this.apidata[key];
        // }
      }
      // that.akeys.push("其他");
      // that.bvalues.push(tmp);

      for (let i = 0; i < akeys.length; i++) {
        countryCount.push({});
        countryCount[i].country = akeys[i];
        countryCount[i].count = bvalues[i];
      }

      const barChartData = Object.assign(this.formatbarchartData(countryCount), {
        format: "%",
        y: "↑ count",
      });
      const chartdata = {}
      chartdata.data = barChartData
      chartdata.length = akeys.length
      this.chartdata = chartdata
      //成功获得影片来源国家与数量键值对0: {name: '美国', value: 1883}
    });

  }
};
</script> 
<style coped>
.container-border .el-card__body {
  padding: 0;
}
</style>