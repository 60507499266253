import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        login_state: window.sessionStorage.getItem('login_state') == '1' ? 1 : 0,
        //前一个页面
        previous_film_page: window.sessionStorage.getItem('previous_film_page') == undefined ? "1" : window.sessionStorage.getItem('previous_film_page'),
        current_film_page: window.sessionStorage.getItem('current_film_page') == undefined ? "1" : window.sessionStorage.getItem('current_film_page'),
        current_page: window.sessionStorage.getItem('current_page') == null ? 1 : window.sessionStorage.getItem('current_page'),
        current_active_index: window.sessionStorage.getItem('current_active_index') == null ? "1" : window.sessionStorage.getItem('current_active_index'),
        current_user: window.sessionStorage.getItem('current_user'),
        current_state: window.sessionStorage.getItem('current_state') == '1' ? "1" : "0",
        current_select_state: window.sessionStorage.getItem('current_select_state') == null ? "3" : window.sessionStorage.getItem('current_select_state'),
        current_select_content: window.sessionStorage.getItem('current_select_content'),
        current_select_text: window.sessionStorage.getItem('current_select_text'),
        current_select_item: window.sessionStorage.getItem('current_select_item'),
        current_path: window.sessionStorage.getItem('current_path') == null ? "https://api.film.yingshinet.com/film1_old" : window.sessionStorage.getItem('current_path'),
        isvis: "picture",
        previousID:-1,
        nextID:-1,
        currentID:-1,
        index:-1,
    },
    mutations: {
        change_current_path(state, path) {
            state.current_path = path
            window.sessionStorage.setItem('current_path', path)
        },
        change_state_in(state) {
            state.login_state = 1
            window.sessionStorage.setItem('login_state', 1)
        },
        change_state_out(state) {
            state.login_state = 0
            window.sessionStorage.setItem('login_state', 0)
        },
        change_current_state(state, page_state) {
            state.current_state = page_state
            window.sessionStorage.setItem('current_state', page_state)
        },
        change_current_select_state(state, select_state) {
            state.current_select_state = select_state
            window.sessionStorage.setItem('current_select_state', select_state)
        },
        change_current_select_content(state, select_content) {
            state.current_select_content = select_content
            window.sessionStorage.setItem('current_select_content', select_content)
        },
        change_current_select_text(state, select_text){
            state.current_select_text = select_text
            window.sessionStorage.setItem('current_select_text', select_text)
        },
        change_current_select_item(state, select_item){
            state.current_select_item = select_item
            window.sessionStorage.setItem('current_select_item', select_item)
        },
        change_current_film_page(state, page) {
            state.current_film_page = page
            window.sessionStorage.setItem('current_film_page', page)
        },
        change_previous_film_page(state, page) {
            state.previous_film_page = page
            window.sessionStorage.setItem('previous_film_page', page)
        },
        change_current_page(state, page) {
            state.current_page = page
            window.sessionStorage.setItem('current_page', page)
        },
        change_current_active_index(state, active_index) {
            state.current_active_index = active_index
            window.sessionStorage.setItem('current_active_index', active_index)
        },
        set_current_user(state, username) {
            state.current_user = username
            window.sessionStorage.setItem('current_user', username)
        },
        //修改词云图图片墙状态
        handlevis (state, vis) {
            state.isvis = vis
        }
    }
})
export default store
