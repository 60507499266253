<template>
  <!-- 底部第一行容器 -->
  <div class="FilmCountCut-Cloud" style="height: 20vh; width: 90vw; margin: 0 auto;">
    <svg id="FilmCountCutCloud" height="100%"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import * as cloud from 'd3-cloud'
import * as $ from 'jQuery'

export default {
  name: 'FilmCountCutCloud',
  props: {
    query_item: "",
  },
  data() {
    return {
      words: [],
      path: 'https://api.film.yingshinet.com',
      send_books: "",
    }
  },
  watch: {
    '$store.state.current_film_page': function () {
      this.getEvents();
    },
    query_item: function () {
      this.getEvents();
    }
  },
  methods: {
    //获得初始数据
    async getEvents() {
      const payload = {
        table: "film_foreign",
        query_item: this.query_item
      };
      switch (this.$store.state.current_film_page) {
        case "1":
          payload.table = "film_foreign";
          break;
        case "2":
          payload.table = "film2";
          break;
        case "3":
          payload.table = "film3";
          break;
        case "4":
          payload.table = "film4";
          break;
        case "5":
          payload.table = "practitioners";
          break;
        case "6":
          payload.table = "film_novel";
          break;
        default:
      };
      await this.$axios.post(this.path + '/film_count_cut', payload).then((response) => {
        const count_length = response.data.length;
        let minSize = d3.min(response.data.wordcut, d => d[0]);
        let maxSize = d3.max(response.data.wordcut, d => d[0]);
        let fontSizeScale;
        if (maxSize - minSize < 10) {
          fontSizeScale = d3.scaleLinear().domain([minSize, maxSize]).range([20, 50]); // 如果数据差异很小，使用线性比例尺
        } else {
          fontSizeScale = d3.scalePow().exponent(0.3).domain([minSize, maxSize]).range([5, 50]); // 如果数据差异很大，使用指数比例尺
        }

        this.words = [];
        for (let i = 0; i < count_length; i++) {
          const figureItem = {};
          figureItem.name = response.data.wordcut[i][1];
          figureItem.count = response.data.wordcut[i][0];
          figureItem.count = fontSizeScale(figureItem.count);
          this.words[i] = {
            text: `${figureItem.name}`,
            size: `${figureItem.count}`
          }
        }
        this.initFilmCountCutCloud();
      })
    },
    // 给每个词加搜索功能
    initFilmCountCutCloud() {
      var that = this;
      this.drawFilmCountCutCloud();  // 先根据this.words绘图
      this.$nextTick(function () {  // 绘图后根据添加的类名，给每个词添加搜索功能
        document.querySelectorAll('.FilmCountCut-word').forEach((item) => {
          item.addEventListener('click', () => {
            // 词云图词汇内容
            let keyword = item.textContent;
            const payload = {
              key: keyword,
            };
            that.send_books = keyword;
            that.getQueryBooks(payload);
          })
        })
      })
    },
    drawFilmCountCutCloud() {
      let w = $('.FilmCountCut-Cloud').width();
      let h = $('.FilmCountCut-Cloud').height();

      let layout = cloud()
                .size([w, h])
                .words(this.words)
                .padding(4)
                .rotate(0)
                .font('Impact')
                .fontSize(d => d.size)
                .on('end', draw);
      layout.start();

      function draw(words) {
        const color = d3.scaleOrdinal(d3.schemeCategory10);
        let svg = d3.select('#FilmCountCutCloud');
        svg.selectAll('*').remove();
        svg.attr('width', layout.size()[0]).attr('height', layout.size()[1]);
        let g = svg.append('g')
                   .attr('transform', 'translate(' + layout.size()[0] / 2 + ',' + layout.size()[1] / 2 + ')');
        let text = g.selectAll('text')
                    .data(words)
                    .enter()
                    .append('a')
                    .attr('class', 'FilmCountCut-word')
                    .append('text')
                    .attr('fill', (_, i) => color(i))
                    .attr('transform', function (d) {
                        return ('translate(' + [Math.random() * 1000 - 500, Math.random() * 1000 - 500] + ')')
                    })
                    .transition()
                    .style('font-size', (d) => { return d.size + 'px' })
                    .style('font-family', 'cloud3')
                    .attr('text-anchor', 'middle')
                    .text((d) => { return d.text })
                    .attr('transform', (d) => {
                        return ('translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')' + 'scale(1.2)')
                    })
      }
    },
    getQueryBooks(payload) {
      this.$parent.select_input = this.send_books;
      this.$parent.film_select();
    },
  },
  mounted() {
    // 词云获得初始数据
    this.getEvents();
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}

.el-card__body {
  padding: 0.5vw;
}

.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.FilmCountCut-word:hover {
  cursor: pointer;
  text-decoration: none;
}

.footerCard .el-card__body {
  padding: 0.5vw;
}

a {
  text-decoration: none
}
</style>