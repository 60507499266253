<template>
  <!-- 底部第一行容器 -->
  <div
    class="voiactors-Cloud"
    style="height: 20vh; width: 90vw; margin: 0 auto;"
  >
    <svg
      id="voiactorsCloud"
      height="100%"
    />
  </div>
</template>

<script>
import * as d3 from 'd3'
import * as cloud from 'd3-cloud'
import * as $ from 'jQuery'
import { log } from 'console'

export default {
  components: {},
  name: 'voiactorsCloud',
  data () {
    return {
      words: [],
      //   fontSizeMapper: (word) => Math.log2(word.value) * 5,
      path: 'https://api.film.yingshinet.com',
      voiactorsCloud: null,
      wordcount: 0,
      wordsdata: [],
      director: '',
      voiactors: [],
      figure_names: [],
      figures_serach: [],
      send_books: ""
    }
  },

  methods: {
    //获得初始数据
    async getEvents () {
      const payload = {
        query_item: "voice_actors",
        table:"film_foreign"
      };
      await this.$axios.post(this.path + '/film_count', payload).then((response) => {
        console.log("res", response)
        const count_length = response.data.length
        let wordsscale = d3.scalePow().exponent(0.3)
        // const tmpdirectors = []
        this.words = []
        for (let i = 0; i < count_length; i++) {
          const figureItem = {}
          figureItem.name = response.data.data[i][0]
          figureItem.count = response.data.data[i][1]
          // tmpdirectors.push(figureItem)
          this.words[i] = {
            text: `${figureItem.name}`,
            size: `${wordsscale(figureItem.count) * 5 + 2}`,
          }
        }
        this.initVoiActorsCloud()
        // console.log(this.actorsCloud);
      })
    },
    // 给每个词加搜索功能
    initVoiActorsCloud () {
      var that = this;
      // 先根据this.words绘图
      this.drawVoiActorsCloud()
      // 绘图后根据添加的类名，给每个词添加搜索功能
      this.$nextTick(function () {
        document.querySelectorAll('.voiactors-word').forEach((item) => {
          item.addEventListener('click', () => {
            // 词云图词汇内容
            let keyword = item.textContent
            const payload = {
              voice_actors: keyword,
            };
            that.send_books = keyword;
            that.getQueryBooks(payload);
          })
        })
      })
    },
    drawVoiActorsCloud () {

      // console.log("词云图开始", this.words);
      let w = $('.voiactors-Cloud').width()
      let h = $('.voiactors-Cloud').height()
      // console.log(w, h);
      let layout = cloud()
        .size([w, h])
        .words(this.words)
        .padding(3)
        .rotate(0)
        .font('Impact')
        .fontSize(function (d) {
          return d.size
        })
        .on('end', draw)

      layout.start()

      function draw (words) {
        const color = d3.scaleOrdinal(d3.schemeCategory10)
        let svg = d3.select('#voiactorsCloud')
        svg.selectAll('*').remove()
        svg.attr('width', layout.size()[0]).attr('height', layout.size()[1])
        let g = svg
          .append('g')
          .attr(
            'transform',
            'translate(' +
            layout.size()[0] / 2 +
            ',' +
            layout.size()[1] / 2 +
            ')'
          )
        let text = g
          .selectAll('text')
          .data(words)
          .enter()
          .append('a')
          .attr('class', 'voiactors-word')
          // .attr("xlint:href", function (d) {
          //   // return "/network?wanted={{jfile}}";
          //   return null;
          // })
          .append('text')
          .attr('fill', (_, i) => color(i))
          .attr('transform', function (d) {
            return (
              'translate(' +
              [Math.random() * 1000 - 500, Math.random() * 1000 - 500] +
              ')'
            )
          })
          .transition()

          .style('font-size', function (d) {
            return d.size + 'px'
          })
          .style('font-family', 'cloud3')
          .attr('text-anchor', 'middle')
          .text(function (d) {
            return d.text
          })
          .attr('transform', function (d) {
            return (
              'translate(' +
              [d.x, d.y] +
              ')rotate(' +
              d.rotate +
              ')' +
              'scale(1.2)'
            )
          })
      }
    },
    getQueryBooks (payload) {
      this.$parent.select_input = this.send_books
      this.$parent.film_select()
      // const path = "https://api.film.yingshinet.com/filmsquery1";
      // this.$axios
      //   .post(path, payload)
      //   .then((res) => {
      //     this.send_books = res.data.books;
      //     if (this.send_books.length != 0) {
      //       this.$router.push({
      //         name: "FilmTranslation",
      //         params: { books: JSON.stringify(this.send_books) },
      //       });
      //     } else {
      //       alert("没有对应的内容！");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
  },
  mounted () {
    // 导演影片数据词云获得初始数据
    this.getEvents()


  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.voiactors-word:hover {
  cursor: pointer;
  text-decoration: none;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
a{
  text-decoration: none
}
</style>
