<template>
  <div id="app">
    <router-view />

    <!-- 底部版权 -->
    <div style="background-color: #6C757D; margin-top:2vw">
      <div style="display:inline-block; margin:0 5vw; vertical-align:top ">
        <div>
          <a href="http://www.cuc.edu.cn/" target="_Blank">
            <img style="height:3.8vw;margin-top:1.5vw" src="./assets/cuclogo.png" />
          </a>
        </div>
        <div style="color:#dbdbdb; padding-top:1.5vw; font-size:1.1vw">©中国传媒大学 外国语言文化学院</div>
        <div style="color:#dbdbdb; padding:0.6vw 0 1.5vw 0; font-size:1vw">©国家社科重大项目：中国电影翻译通史-数据库</div>
      </div>
      <div
        style="width:1px; background-color:#bbbbbb; height:12vw; display:inline-block; vertical-align:top"
      ></div>
      <div style="display:inline-block">
        <div style="display:inline-block;margin-left:4vw; vertical-align:top">
          <div style="padding-top:1.5vw; font-size:1.2vw">
            <a href="./#/home" class="mouse_over">首页</a>
          </div>
          <div style="padding-top:1vw; font-size:1vw">
            <div class="mouse_over" @click="gofilm()">影片</div>
          </div>
          <div style="padding:1vw 0 0 0; font-size:1vw">
            <a href="./#/login" class="mouse_over">个人中心</a>
          </div>
        </div>
        <div style="display:inline-block;margin-left:4vw; vertical-align:top">
          <div style="color:#ffffff; padding-top:1.5vw; font-size:1.2vw">相关链接</div>
          <div style="color:#dbdbdb; padding:1vw 0 0 0; font-size:1vw">
            <a class="mouse_over" target="_Blank" href="http://sis.cuc.edu.cn/">外国语言文化学院</a>
          </div>
          <div style="padding:1vw 0 0 0; font-size:1vw">
            <a href="./#/acknowledgement" class="mouse_over">致谢</a>
          </div>
        </div>
        <div style="display:inline-block;margin-left:4vw; vertical-align:top">
          <div style="color:#ffffff; padding:1.5vw 0 0 0; font-size:1.2vw">
            <a class="mouse_over" target="_Blank" href="http://www.cuc.edu.cn/">中国传媒大学</a>
          </div>
          <div style="color:#dbdbdb; padding:1vw 0 0 0; font-size:1vw">
            <a class="mouse_over" target="_Blank" href="http://ccs.cuc.edu.cn/">计算机与网络空间安全学院</a>
          </div>
        </div>
        <div style="height:100%; color:#c5c5c5; margin-left:4vw;padding-top:2.2vw;position:relative; font-size:0.9vw">
            <div>技术支持：@中国传媒大学计算机与网络空间安全学院CBD实验室</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return{
    }
  },
  methods: {
    gofilm() {
      this.$store.commit("change_current_select_content",JSON.stringify(null))
      this.$router.push("/filmTranslation");
    },
  }
})
</script>

<style>
#app {
  margin-top: 60px;
}
.mouse_over {
  color: #dbdbdb;
}
.mouse_over:hover {
  color: #17a2b8;
  cursor: pointer;
}
</style>
