<template>
  <!-- 底部第一行容器 -->
  <div class="countrys-Map" style="height: 50vh; width: 90vw; margin: 0 auto">
    <div id="container" height="100%"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as $ from "jQuery";
import { lazyBMapApiLoaderInstance } from "vue-bmap-gl";
export default {
  name: "CountrysMap",
  data() {
    return {
      map: null,
      centerPoint: {
        lng: 12.29,
        lat: 41.54,
      },
      zoom: 0.1,
      mapType: "BMAP_EARTH_MAP",
      countrys: [],
      current_marker_color:'',
      label:null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    NavigationControl3D() {
      // eslint-disable-next-line no-undef
      var navi3DCtrl = new BMapGL.NavigationControl3D(); // 添加3D控件
      this.map.addControl(navi3DCtrl);
    },
    init() {
      lazyBMapApiLoaderInstance.load().then(() => {
        // 绘制带高度的点
        this.$axios.get("country_position.json").then((res) => {
          this.countrys = res.data.countrys;
          const GL = BMapGL;
          this.map = new GL.Map("container", {
            minZoom: 0.01,
            maxZoom: 20,
          }); // 创建Map实例
          this.map.centerAndZoom(
            new GL.Point(this.centerPoint.lng, this.centerPoint.lat),
            this.zoom
          );
          for (let i = 0; i < this.countrys.length; i++) {
            var that = this;
            var each_name = this.countrys[i].name;
            var each_position = this.countrys[i].position;
            var each_number = this.countrys[i].film_num;
            var each_lang = each_position.split("[")[1];
            var lang = each_lang.split(",")[0];
            var lat = each_lang.split(",")[1];
            var each_size = (each_number - 1) / 3536;
            lat = lat.split("]")[0];
            // 地址解析获取对应经纬度
            //亚洲 (绿色)
            if (lang > 60 && lang < 170 && lat < 80 && lat > -10) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#e377c2",
              });
            }
            //欧洲（粉色）
            else if (lang > -10 && lang < 60 && lat < 71 && lat > 36) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#2ca02c",
              });
            }
            //非洲（草绿）
            else if (lang > -20 && lang < 50 && lat < 35 && lat > -35) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#bcbd22",
              });
            }
            //北美（浅蓝）
            else if (lang > -170 && lang < -20 && lat < 72 && lat > 7) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#17becf",
              });
            }
            //南美（蓝色）
            else if (lang > -80 && lang < -40 && lat < 12 && lat > -54) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#1f77b4",
              });
            }
            //大洋洲（橙色）
            else if (lang > 110 && lang < 150 && lat < 10 && lat > -55) {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#ff7f0e",
              });
            }
            //其他（紫色）
            else {
              var pt = new BMapGL.Point(lang, lat);
              var marker = new BMapGL.Marker3D(pt, each_number, {
                size: each_size * 100 + 15,
                shape: "BMAP_SHAPE_CIRCLE",
                fillColor: "#ff7f0e",
              });
            }

            // 添加鼠标事件
            marker.addEventListener("mouseover", function (e) {
              this.current_marker_color = e.target.getFillColor()
              //添加label，鼠标未点击时隐藏
              var marker_position = e.target.getPosition();
              var number = e.target.getHeight();
              this.label = new BMapGL.Label(number, {
                position: marker_position,
              });
              this.label.setStyle({
                border: "none",
                color: "black",
                backgroundColor: "transparent",
                fontSize: "20px",
                opacity:'1'
              });
              e.target.setFillColor("red");
              that.map.addOverlay(this.label);
            });
            marker.addEventListener("mouseout", function (e) {
              e.target.setFillColor(this.current_marker_color);
              //删除属性为Label的覆盖物
              var allOverlay = that.map.getOverlays();
              // console.log(allOverlay)
              // if (allOverlay.length) {
              //   for (var j = 0; j < allOverlay.length; j++) {
              //     if (allOverlay[j].toString() == "[object Label]") {
              //       that.map.removeOverlay(allOverlay[j]);
              //     }
              //   }
              // }
              // console.log(allOverlay)
              this.label.setStyle({
                border: "none",
                color: "black",
                backgroundColor: "transparent",
                fontSize: "20px",
                opacity:'0'
              });
            });
            // // 添加鼠标事件
            // label.addEventListener("mouseover", function (e) {
            //   e.target.setStyle({
            //   opacity: "1"})
            // });
            // label.addEventListener("mouseout", function (e) {
            //   e.target.setStyle({
            //   opacity: "0"})
            // });
            that.map.addOverlay(marker);
            // that.map.addOverlay(label);
          }
          this.map.enableScrollWheelZoom(true);
          this.map.setMapType(this.mapType);
          this.NavigationControl3D();
        });
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.countrys-word:hover {
  cursor: pointer;
  text-decoration: none;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
#container {
  width: 100%;
  height: 50vh;
}
</style>
</script>