<template>
    <!-- 底部第一行容器 -->
    <div
      style="height: 80vh; width: 100%;align:center;"
    >
    <h1 style="font-size:30px; margin: 0 40% ">中国电影翻译史</h1>
    <span style="font-size:20px; margin: 0 40% ">汉译库{{count}}条</span>
    <div  style="margin: 10px 40%">
    <el-select v-model="selectedcountry" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item"
      :value="item">
    </el-option>
  </el-select>
  <b-button @click="choose" style="width:5vw;height:30px;font-size:1vw">检索</b-button>
   </div>
      <svg
        id="time"
        height="100%"
		width="100%"
      />
		
    </div>
  </template>
  
  <script>
  import * as d3 from 'd3'
  import * as cloud from 'd3-cloud'
  import * as $ from 'jQuery'
  import { log } from 'console'
   

  export default {
    components: {},
    name: 'countrysCloud',
    data () {
      return {
        path: 'https://api.film.yingshinet.com',
        selectedcountry:'法国',
        countyear1:'120',
        selectvalue:0,
        dataset:[],
        count:0,
      options: ['日本', '西班牙', '埃及', '朝鲜', '意大利', '英国',
        '美国', '墨西哥', '印度', '丹麦', '阿根廷', '南非', '博茨瓦纳',
        '法国', '德国', '加拿大', '瑞典', '韩国', '菲律宾', '中国香港',
        '澳大利亚', '奥地利', '俄罗斯', '肯尼亚', '阿尔及利亚', '中国台湾',
        '爱尔兰', '匈牙利', '捷克', '斯洛伐克', '波兰', '比利时', '巴西',
        '土耳其', '荷兰', '马耳他', '摩洛哥', '中国大陆', '捷克斯洛伐克',
        '新加坡', '新西兰', '瑞士', '巴拉圭', '乌拉圭', '芬兰', '爱沙尼亚',
        '以色列', '罗马尼亚', '苏联', '苏联+俄罗斯', '伊朗', '泰国', '波黑', '克罗地亚',
        '塞尔维亚', '斯洛文尼亚', '北马其顿', '列支敦士登', '巴哈马', '卢森堡', '越南',
        '摩纳哥', '保加利亚', '阿联酋', '卡塔尔', '突尼斯', '葡萄牙', '东西洋各国', '希腊',
        '智利', '马来西亚', '乌克兰', '黎巴嫩', '塔吉克斯坦', '哥伦比亚', '巴基斯坦',
        '挪威', '南斯拉夫', '印度尼西亚', '伊拉克', '西德', '冰岛', '阿尔巴尼亚',
        '玻利维亚', '古巴', '缅甸', '几内亚', '塞内加尔', '阿塞拜疆', '斯里兰卡',
        '委内瑞拉', '叙利亚', '尼泊尔']}
    },
  
    methods: {
        drawTimeGraph(){
        let width=(window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth)*0.98;
        let height=(window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight)*1.2;
        const fill = d3.scaleOrdinal(d3.schemeCategory10);
		let yeardata=new Array(30);
        for (let i=0;i<30;i++){
            yeardata[i]=i*5;
        }
        //提示框部分
		let tooltip=d3.selectAll("body")
                    .append("div")
                    .attr("class","tooltip")
                    .style("opacity",0.0);
      		let pic = d3.selectAll("pic")
        		.append("image")
        		.attr("class", "pic")
        		.style("opacity", 0.0);
            let svg = d3.select("#time")			//选择<body>
					.append("svg")			//在<body>中添加<svg>
					.attr("width", width)	//设定<svg>的宽度属性
					.attr("height", height);//设定<svg>的高度属性
		//矩形所占的宽度（包括空白），单位为像素
		let rectStep =width/this.countyear1;
		//矩形所占的宽度（不包括空白），单位为像素
		let  img_h=60;
		let  img_w=50;
		let  radius=5;
		let rectWidth =rectStep-2;
	                     let j=0;
	  		let imgs=svg.selectAll(".img")
	               .data(this.dataset)
				   .enter()
				   .append("rect")
				   .attr("class","img")
				   .attr("x", function(d,i){
					    return (d.release_time_original-1895)*11+60-radius;
				    })
				   .attr("y", function(d,i){
				       if ((i>0)&&(d.release_time_others==d.release_time_original))
				       {
				           let y=height/2-(j+1)%(d.release_time_original-1895)*2*radius-53;
				           j=j+1;
				           return y;
					   }
					   else
					   {
					    	j=0;
					       return height/2-j%(d.release_time_original-1895)*2*radius-53;
					   }
				    })
				   .attr("width",radius*2)
				   .attr("height",radius*2)
				   .style("stroke","none")
				   .style("stroke-width","0.5px")
				   .attr("fill", function(d, i){
                                    //创建圆形图片
                                    let defs = svg.append("defs").attr("id", "imgdefs");
                                    let catpattern = defs.append("pattern")
                                                         .attr("id", "catpattern" + i)
                                                         .attr("height", 1)
                                                         .attr("width", 1);
                                    catpattern.append("image")
                                            .attr("x", - (img_w / 2 - radius+5.8))
                                            .attr("y", - (img_h / 2 - radius+3.5))
                                            .attr("width", img_w+11)
                                            .attr("height", img_h+6)
                                            .attr("xlink:href","https://assets.yingshinet.com/chinese_film_translation/film_foreignsmall/"+d.filmImageFile);
                                    return "url(#catpattern" + i + ")";
                    })
                    .on("mouseover",function(d,i){
					   tooltip.html(d.transname_chi_mainland+"-"+d.release_time_original+"发行"+"</br>"+"导演："+(d.director_original))
					   .style("position", "absolute")
                            .style("left", (event.pageX) + "px")
                            .style("top", (event.pageY + 20) + "px")
                            .style("opacity",1.0);
					   d3.select(this)
					     .style("cursor","pointer");
          pic = svg.append("image")
            .attr("x", 50)
            .attr("y", 0)
            .attr("width", img_w * 3)
            .attr("height", img_h * 3)
            .attr("xlink:href", "https://assets.yingshinet.com/chinese_film_translation/film_foreign/" + d.filmImageFile)
            .style("opacity", 1.0);
				   })
				   .on("mousemove",function(d){
					  tooltip.style("position", "absolute")
					    .style("left", (event.pageX) + "px")
					    .style("top", (event.pageY + 20) + "px");
				   })
				   .on("mouseout",function(d){
					  tooltip.style("opacity",0.0);
					  pic.style("opacity", 0.0);
				   });

        
     svg.append("line")
           .attr("x1",20)
           .attr("y1",height/2-40)
           .attr("x2",width-20)
           .attr("y2",height/2-40)
           .attr("stroke","black")
           .attr("stroke-width",1)
           .attr("opacity",1);
		let year = svg.selectAll(".year")
				.data(yeardata)			//绑定数据
				.enter()				//获取enter部分
				.append("text")			//添加text元素，使其与绑定数组的长度一致
				.attr("class","year")
				.attr("fill", "black")
				.attr("stroke", "none")
				.attr("font-size","20px")
				.attr("font-family","宋体")
				.attr("text-anchor","middle")
				.attr("x", function(d,i){
					return d*11;
				})
				.attr("y", height/2)
				.attr("dx","60")
				.attr("dy","-1em")
				.text(function(d,i){
				      return d+1895;
				});
            },
      //获得初始数据
      choose(row){
        console.log(this.selectedcountry)
        d3.selectAll("svg > *").remove();
        this.getEvents()
	},
      async getEvents () {
        await this.$axios.post(this.path + '/filmpic1',{"country":this.selectedcountry}).then((response) => {
          this.dataset=response.data.data;
          this.count=response.data.count;
          console.log(this.count)
		  for(let i=1;i<this.count;i++)
		  {
			this.dataset[i].release_time_others=this.dataset[i-1].release_time_original;
		  }
          this.drawTimeGraph()
        })
    },

},
     mounted () {
      //  // 导演影片数据词云获得初始数据
      this.getEvents()
    },
}


</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
    font-size: 0.7vw;
  }
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  
  .el-carousel__indicators li {
    border: 0.5vw;
  }
  .el-card__body {
    padding: 0.5vw;
  }
  .footerCard {
    margin: 0.5vw;
    margin-left: 0.5vw;
  }
  </style>
  
  <style>
  .countrys-word:hover {
    cursor: pointer;
    text-decoration: none;
  }
  .footerCard .el-card__body {
    padding: 0.5vw;
  }
  </style>
  
