<template>
  <div align="center">
    <!-- 导航栏 -->
    <div>
      <el-menu :default-active="activeIndex" class="el-menu-demo menu" mode="horizontal" background-color="#17A2B8"
        text-color="#eee" active-text-color="#fff">
        <el-menu-item style="font-size:20px; padding: 0 !important; color:#fff !important"
          v-if="$store.state.login_state">【管理员系统】</el-menu-item>
        <el-menu-item index="1" @click="film_foreign_click()">外国电影汉译库</el-menu-item>
        <el-menu-item index="2" @click="film_nation_click()">民族语译制库</el-menu-item>
        <el-menu-item index="3" @click="film_china_click()">中国电影外译库</el-menu-item>
        <el-menu-item index="4" @click="film_help_click()">无障碍翻译库</el-menu-item>
        <el-menu-item index="5" @click="film_translator_click()">译制工作者库</el-menu-item>       
        <el-menu-item index="6" @click="film_novel_click()">电影小说库</el-menu-item>
        <b-button
          style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0);height:40px;font-size:15px;margin:10px 25px 0 0; float:right"
          @click="go_login()" v-if="!$store.state.login_state">登录</b-button>
        <b-button
          style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0);height:40px;font-size:15px;margin:10px 25px 0 0; float:right"
          @click="logout()" v-else>登出</b-button>
      </el-menu>
    </div>
    <div style="width:90%">
      <div class="row">
        <div>
          <div style="margin:20px 0 30px 0">
            <a href="./#/">
              <img src="../assets/top2.jpg" />
            </a>
          </div>

          <!-- 搜索框 -->
          <div>
            <b-input-group
              style="width:80%;height:45px; margin-bottom:30px; box-shadow: 2px 2px 5px #aaa; border-radius:5px">
              <template v-slot:prepend>
                <b-form-select v-model="selected" :options="currentSearchType"
                  style="border-radius:5px 0 0 5px; width:12vw; height:45px"></b-form-select>
              </template>
              <b-form-input style="height:45px" placeholder="请输入检索内容" v-model="select_input"></b-form-input>
              <template v-slot:append>
                <div class="btn-group" role="group" style>
                  <b-button variant="info" style="width:5vw;height:45px;font-size:1vw" @click="film_select">检索</b-button>
                  <b-button style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0); width:5vw;height:45px;font-size:1vw"
                    v-show="$store.state.login_state && ['1','2','3','4'].includes($store.state.current_film_page)" @click="add_translation_film()">新增</b-button>
                  <b-button style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0); width:5vw;height:45px;font-size:1vw"
                    v-show="$store.state.login_state && $store.state.current_film_page == '5'" @click="add_translator_film()">新增</b-button>
                  <b-button style="background-color:#F0755C; border-color:rgba(255, 255, 255, 0); width:5vw;height:45px;font-size:1vw"
                    v-show="$store.state.login_state && $store.state.current_film_page == '6'" @click="add_novel_film()">新增</b-button>
                  <b-button style="width:5vw;height:45px;font-size:1vw" @click="reset()">重置</b-button>
                </div>
              </template>
            </b-input-group>
          </div>
          <!-- 可视化组件 -->
          <FilmCountCutCloud v-if="this.selected == 1 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="name_original"></FilmCountCutCloud>
          <FilmCountCutCloud v-if="['1','2','3'].includes(this.selected) && this.$store.state.current_film_page == '5'" v-bind:query_item="p_name"></FilmCountCutCloud>
          <FilmCountCutCloud v-if="this.selected == 1 && this.$store.state.current_film_page == '6'" v-bind:query_item="film_name"></FilmCountCutCloud>
          <FilmCountCutCloud v-if="this.selected == 2 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="transname_chi_mainland" ></FilmCountCutCloud>
          <FilmCountCutCloud v-if="this.selected == 2 && this.$store.state.current_film_page == '6'" v-bind:query_item="film_director"></FilmCountCutCloud>
          <FilmCountCloud v-if="this.selected == 3 && ['1','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="director_original"></FilmCountCloud>
          <FilmCountCutCloud v-if="this.selected == 3 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_title"></FilmCountCutCloud>
          <FilmCountCloud v-if="this.selected == 4 &&  ['1','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="actors_original"></FilmCountCloud>
          <FilmCountCutCloud v-if="this.selected == 4 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_author"></FilmCountCutCloud>
          <LanguagesCloud v-if="this.selected == 5 && ['1','2','3'].includes(this.$store.state.current_film_page)"></LanguagesCloud> 
          <FilmCountCutCloud v-if="this.selected == 5 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_publish_company"></FilmCountCutCloud>
          <div v-if="this.selected == 6 && this.$store.state.current_film_page == '1'" style="height: 30vh; width: 100%;">
            <el-col :span="15">
              <BarChart></BarChart>
            </el-col>
            <el-col :span="9">
              <CountrysCloud></CountrysCloud>
            </el-col>
          </div>
          <FilmCountCutCloud v-if="this.selected == 6 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_release_time"></FilmCountCutCloud>
          <TypeCloud v-if="this.selected == 7 && ['1', '2','3'].includes(this.$store.state.current_film_page)"></TypeCloud>
          <DirTranslationCloud v-if="this.selected == 8 && this.$store.state.current_film_page == '1'"></DirTranslationCloud>
          <FilmCountCloud v-if="this.selected == 9 && this.$store.state.current_film_page == '1'" v-bind:query_item="translators"></FilmCountCloud>
          <FilmCountCloud v-if="this.selected == 10 && this.$store.state.current_film_page == '1'" v-bind:query_item="voice_actors"></FilmCountCloud>
          <FilmCountCloud v-if="this.selected == 11 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="agency_translation"></FilmCountCloud>
          <FilmCountCloud v-if="this.selected == 12 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="produce_time_original"></FilmCountCloud>
          <FilmCountCloud v-if="this.selected == 13 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="release_time_mainland"></FilmCountCloud>
          <!-- <TitlesCloud v-if="this.selected == 1 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="name_original"></TitlesCloud>
          <TitlesCloud v-if="['1','2','3'].includes(this.selected) && this.$store.state.current_film_page == '5'" v-bind:query_item="p_name"></TitlesCloud>
          <TitlesCloud v-if="this.selected == 1 && this.$store.state.current_film_page == '6'" v-bind:query_item="film_name"></TitlesCloud>
          <TitlesCloud v-if="this.selected == 2 && ['1','2','3'].includes(this.$store.state.current_film_page)" v-bind:query_item="transname_chi_mainland" ></TitlesCloud>
          <TitlesCloud v-if="this.selected == 2 && this.$store.state.current_film_page == '6'" v-bind:query_item="film_director"></TitlesCloud>
          <DirectorsCloud v-if="this.selected == 3 && ['1','3'].includes(this.$store.state.current_film_page)"></DirectorsCloud>
          <TitlesCloud v-if="this.selected == 3 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_title"></TitlesCloud>
          <ActorsCloud v-if="this.selected == 4 &&  ['1','3'].includes(this.$store.state.current_film_page)"></ActorsCloud>
          <TitlesCloud v-if="this.selected == 4 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_author"></TitlesCloud>
          <TitlesCloud v-if="this.selected == 5 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_publish_company"></TitlesCloud>
          <TitlesCloud v-if="this.selected == 6 && this.$store.state.current_film_page == '6'" v-bind:query_item="novel_release_time"></TitlesCloud>
          <TranslatorsCloud v-if="this.selected == 9 && this.$store.state.current_film_page == '1'"></TranslatorsCloud>
          <VoiActorsCloud v-if="this.selected == 10 && this.$store.state.current_film_page == '1'"></VoiActorsCloud>
          <AgencyTransCloud v-if="this.selected == 11 && ['1', '2','3'].includes(this.$store.state.current_film_page)"></AgencyTransCloud>
          <ProduceTimeCloud v-if="this.selected == 12 && ['1', '2','3'].includes(this.$store.state.current_film_page)"></ProduceTimeCloud>
          <ReleaseMainlandCloud v-if="this.selected == 13 && ['1', '2','3'].includes(this.$store.state.current_film_page)"></ReleaseMainlandCloud> -->
          <!-- 分页显示 -->
          <div v-if="$store.state.login_state" style="display:inline-block; vertical-align:middle">
            <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="items"
            :current-page.sync="currentPage"
            @current-change="pageChange"
            @size-change="sizeChange"
            style="margin:30px 0 30px 0"
            ></el-pagination>
          </div>
          <div v-else style="display:inline-block; vertical-align:middle">
            <p style="font-size: 16px; vertical-align:middle;">项目内部使用，请<a href="./#/login">登录</a>后查看更多内容。项目结题后（2025年）数据开源，谢谢关注 :）</p>
          </div>
          <b-button v-if="$store.state.login_state" v-show="this.$store.state.current_film_page == 1" style="display:inline-block; vertical-align:middle; margin-left:20px; background-color:#F0755C; border-color:rgba(255, 255, 255, 0); color:#fff" @click="foreign_old_order">{{ foreign_current_order }}</b-button>
          <b-button v-if="$store.state.login_state" v-show="this.$store.state.current_film_page == 3" style="display:inline-block; vertical-align:middle; margin-left:20px; background-color:#F0755C; border-color:rgba(255, 255, 255, 0); color:#fff" @click="china_old_order">{{ china_current_order }}</b-button>
          <b-button v-if="$store.state.login_state" v-show="this.$store.state.current_film_page == 6" style="display:inline-block; vertical-align:middle; margin-left:20px; background-color:#F0755C; border-color:rgba(255, 255, 255, 0); color:#fff" @click="novel_old_order">{{ novel_current_order }}</b-button>
          <!-- 其他页面的表格显示、修改、删除 -->
          <!-- 外国电影汉译库 & 无障碍翻译库 -->
          <table v-show="this.$store.state.current_film_page == 1 || this.$store.state.current_film_page == 4" class="table table-hover" style width="90vw">
            <thead align="center">
              <tr>
                <th scope="col" width="150px">编号</th>
                <th scope="col" width="600px">电影原名</th>
                <th scope="col" width="500px">电影译名</th>
                <th scope="col" width="250px">翻译方式</th>
                <th scope="col" width="400px">原片语种</th>
                <th scope="col" width="200px">原产国</th>
                <th scope="col" width="300px">电影类型</th>
                <th scope="col" width="300px">导演</th>
                <th scope="col" width="300px">上映时间（大陆）</th>
                <th scope="col" width="180px">海报</th>
                <th width="350px"></th>
              </tr>
            </thead>
            <tbody align="center" style="word-break:break-all">
              <tr v-for="(book, index) in books" :key="index" @click="films(index,book.idfilms)">
                <td>{{ index + items * (currentPage - 1) + 1 }}</td>
                <td style="color:#078a9e" class="film_name">{{ book.name_original }}</td>
                <td>{{ book.transname_chi_mainland }}</td>
                <td>{{ book.type_translation }}</td>
                <td>{{ book.language_original }}</td>
                <td>{{ book.country_produce }}</td>
                <td>{{ book.film_type }}</td>
                <td>{{ book.director_original }}</td>
                <td>{{ book.release_time_mainland }}</td>

                <td>
                  <el-image :src="book.filmImageFile" class="image_style">
                    <div slot="error" class="image-slot"></div>
                  </el-image>
                </td>
                <td>
                  <b-button variant="info" class="btn-sm" @click="films(index,book.idfilms)" v-if="!$store.state.login_state">
                    详情</b-button>
                  <div class="btn-group" role="group" v-else>
                    <b-button variant="info" class="btn-sm" @click.stop="films(index,book.idfilms)">详情</b-button>
                    <b-button v-show="['1','2','3','4'].includes($store.state.current_film_page)" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translation(book)">修改</b-button>
                    <!-- <b-button v-show="$store.state.current_film_page == 5" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translator(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_novel(book)">修改</b-button> -->
                    <b-button v-show="$store.state.current_film_page == 1" class="btn-sm" @click.stop="make_sure_delete(book.idfilms)">删除</b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 民族语译制库 -->
          <table v-show="this.$store.state.current_film_page == 2" class="table table-hover" style width="90vw">
            <thead align="center">
              <tr>
                <th scope="col" width="150px">编号</th>
                <th scope="col" width="600px">电影原名</th>
                <th scope="col" width="500px">电影译名</th>
                <th scope="col" width="250px">翻译方式</th>
                <th scope="col" width="400px">原片语种</th>
                <th scope="col" width="200px">原产国</th>
                <th scope="col" width="300px">电影类型</th>
                <th scope="col" width="300px">导演</th>
                <th scope="col" width="180px">海报</th>
                <th width="350px"></th>
              </tr>
            </thead>
            <tbody align="center" style="word-break:break-all">
              <tr v-for="(book, index) in books" :key="index" @click="films(index,book.idfilms)">
                <td>{{ index + items * (currentPage - 1) + 1 }}</td>
                <td style="color:#078a9e" class="film_name">{{ book.name_original }}</td>
                <td>{{ book.transname_chi_mainland}}</td>
                <td>{{ book.type_translation }}</td>
                <td>{{ book.language_original }}</td>
                <td>{{ book.country_produce }}</td>
                <td>{{ book.film_type }}</td>
                <td>{{ book.director_original }}</td>

                <td>
                  <el-image :src="book.filmImageFile" class="image_style">
                    <div slot="error" class="image-slot"></div>
                  </el-image>
                </td>
                <td>
                  <b-button variant="info" class="btn-sm" @click="films(index,book.idfilms)" v-if="!$store.state.login_state">
                    详情</b-button>
                  <div class="btn-group" role="group" v-else>
                    <b-button variant="info" class="btn-sm" @click.stop="films(index,book.idfilms)">详情</b-button>
                    <b-button v-show="['1','2','3','4'].includes($store.state.current_film_page)" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translation(book)">修改</b-button>
                    <!-- <b-button v-show="$store.state.current_film_page == 5" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translator(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_novel(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 1" class="btn-sm" @click.stop="make_sure_delete(book.idfilms)">删除</b-button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--中国电影外译库-->
          <table v-show="this.$store.state.current_film_page == 3 " class="table table-hover" style width="90vw">
            <thead align="center">
              <tr>
                <th scope="col" width="150px">编号</th>
                <th scope="col" width="300px">出品年份</th>
                <th scope="col" width="500px">电影原名</th>
                <th scope="col" width="450px">电影译名</th>
                <th scope="col" width="300px">导演</th>
                <th scope="col" width="300px">外译语种</th>
                <th scope="col" width="300px">翻译方式</th>
                <th scope="col" width="180px">海报</th>
                <th width="300px"></th>
              </tr>
            </thead>
            <tbody align="center" style="word-break:break-all">
              <tr v-for="(book, index) in books" :key="index" @click="films(index,book.idfilms)">
                <td>{{ index + items * (currentPage - 1) + 1 }}</td>
                <td>{{ book.produce_time_original }}</td>
                <td style="color:#078a9e" class="film_name">{{ book.name_original }}</td>
                <td>{{ book.transname_chi_mainland }}{{(book.transname_chi_others!=null&&"")?"/"+book.transname_chi_others:""}}</td>
                <td style="color:#078a9e;cursor: pointer;"  @click="movie_web(book.director_original)">{{ book.director_original }}</td>
                <td>{{ book.language_aim }}</td>
                <td>{{ book.type_translation }}</td>

                <td>
                  <el-image :src="book.filmImageFile" class="image_style">
                    <div slot="error" class="image-slot"></div>
                  </el-image>
                </td>
                <td>
                  <b-button variant="info" class="btn-sm" @click="films(index,book.idfilms)" v-if="!$store.state.login_state">
                    详情</b-button>
                  <div class="btn-group" role="group" v-else>
                    <b-button variant="info" class="btn-sm" @click.stop="films(index,book.idfilms)">详情</b-button>
                    <b-button v-show="['1','2','3','4'].includes($store.state.current_film_page)" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translation(book)">修改</b-button>
                    <!-- <b-button v-show="$store.state.current_film_page == 5" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translator(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_novel(book)">修改</b-button> -->
                    <b-button v-show="$store.state.current_film_page == 3" class="btn-sm" @click.stop="make_sure_delete(book.idfilms)">删除</b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--译制工作者库-->
          <table v-show="this.$store.state.current_film_page == 5" class="table table-hover" style width="90vw">
            <thead align="center">
              <tr>
                <th scope="col" width="150px">编号</th>
                <th scope="col" width="200px">姓名</th>
                <th scope="col" width="200px">职业</th>
                <th scope="col" width="2130px">个人简介</th>
                <th scope="col" width="200px">照片</th>
                <th width="350px"></th>
              </tr>
            </thead>
            <tbody align="center" style="word-break:break-all">
              <tr v-for="(book, index) in books" :key="index" @click="films(index,book.idfilms)">
                <td>{{ index + items * (currentPage - 1) + 1 }}</td>
                <td style="color:#078a9e" class="film_name">{{ book.p_name }}</td>
                <td v-show="book.isactor == 1 && book.isdirector == 1 &&book.istranslator == 1">配音演员/译制导演/翻译</td>
                <td v-show="book.isactor == 1 && book.isdirector == 1 &&book.istranslator == 0">配音演员/译制导演</td>
                <td v-show="book.isactor == 1 && book.isdirector == 0 &&book.istranslator == 0">配音演员</td>
                <td v-show="book.isactor == 0 && book.isdirector == 1 &&book.istranslator == 1">译制导演/翻译</td>
                <td v-show="book.isactor == 0 && book.isdirector == 1 &&book.istranslator == 0">译制导演</td>
                <td v-show="book.isactor == 0 && book.isdirector == 0 &&book.istranslator == 1">翻译</td>
                <td>{{ book.p_intro }}</td>
                <td>
                  <el-image :src="book.filmImageFile" class="image_style">
                    <div slot="error" class="image-slot"></div>
                  </el-image>
                </td>
                <td>
                  <b-button variant="info" class="btn-sm" @click="films(index,book.idfilms)" v-if="!$store.state.login_state">
                    详情</b-button>
                  <div class="btn-group" role="group" v-else>
                    <b-button variant="info" class="btn-sm" @click.stop="films(index,book.idfilms)">详情</b-button>
                    <!-- <b-button v-show="['1','2','3','4'].includes($store.state.current_film_page)" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translation(book)">修改</b-button> -->
                    <b-button v-show="$store.state.current_film_page == 5" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translator(book)">修改</b-button>
                    <!-- <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_novel(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 1" class="btn-sm" @click.stop="make_sure_delete(book.idfilms)">删除</b-button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 电影小说库 -->
          <table v-show="this.$store.state.current_film_page == 6" class="table table-hover" style width="90vw">
            <thead align="center">
              <tr>
                <th scope="col" width="150px">编号</th>
                <th scope="col" width="500px">电影</th>
                <th scope="col" width="350px">导演</th>
                <th scope="col" width="500px">电影小说</th>
                <th scope="col" width="200px">作者/改编者</th>
                <th scope="col" width="470px">发表报刊/出版单位</th>
                <th scope="col" width="300px">发表/出版时间</th>
                <th width="350px"></th>
              </tr>
            </thead>
            <tbody align="center" style="word-break:break-all">
              <tr v-for="(book, index) in books" :key="index" @click="films(index, book.idfilms)">
                <td>{{ index + items * (currentPage - 1) + 1 }}</td>
                <td style="color: #078a9e" class="film_name">{{ book.film_name }}</td>
                <td>{{ book.film_director }}</td>
                <td>{{ book.novel_title}}</td>
                <td>{{ book.novel_author }}</td>
                <td>{{ book.novel_publish_company }}</td>
                <td>{{ book.novel_release_time }}</td>
                <td>
                  <b-button variant="info" class="btn-sm" @click="films(index, book.idfilms)" v-if="!$store.state.login_state">
                    详情</b-button>
                  <div class="btn-group" role="group" v-else>
                    <b-button variant="info" class="btn-sm" @click.stop="films(index, book.idfilms)">详情</b-button>
                    <!-- <b-button v-show="['1','2','3','4'].includes($store.state.current_film_page)" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translation(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 5" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_translator(book)">修改</b-button> -->
                    <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" style="background-color:#F0755C; 
                      border-color:rgba(255, 255, 255, 0)" @click.stop="edit_film_novel(book)">修改</b-button>
                    <b-button v-show="$store.state.current_film_page == 6" class="btn-sm" @click.stop="make_sure_delete(book.idfilms)">删除</b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-dialog title="提示" :visible.sync="make_sure_delete_but" width="50%" append-to-body>
            <span>确定要删除？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="make_sure_delete_but = false">取 消</el-button>
              <el-button type="warning" @click="delete_film_translation()">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 分页显示 -->
          <div v-if="$store.state.login_state">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total" :page-size="items"
            :current-page.sync="currentPage" @current-change="pageChange" @size-change="sizeChange"
            style="margin:35px 0 40px 0"></el-pagination>
          </div>
          <div v-else>
            <p>项目内部使用，请<a href="./#/login">登录</a>后查看更多内容。项目结题后（2025年）数据开源，谢谢关注 :）</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as d3 from "d3";
import BarChart from "./home/elementvue/BarChart.vue";
import CountrysMap from "./home/elementvue/CountrysMap.vue";
import DirectorsCloud from "./home/elementvue/DirectorsCloud.vue";
import ActorsCloud from "./home/elementvue/ActorsCloud.vue";
import CountrysCloud from "./home/elementvue/CountrysCloud.vue";
import TitlesCloud from "./home/elementvue/TitlesCloud.vue";
import LanguagesCloud from "./home/elementvue/LanguagesCloud.vue";
import DirTranslationCloud from "./home/elementvue/DirTranslationCloud.vue";
import TranslatorsCloud from "./home/elementvue/TranslatorsCloud.vue";
import VoiActorsCloud from "./home/elementvue/VoiActorsCloud.vue";
import TypeCloud from "./home/elementvue/TypeCloud.vue";
import AgencyTransCloud from "./home/elementvue/AgencyTransCloud.vue";
import ProduceTimeCloud from "./home/elementvue/ProduceTimeCloud.vue";
import ReleaseMainlandCloud from "./home/elementvue/ReleaseMainlandCloud.vue";
import FilmCountCloud from "./home/elementvue/FilmCountCloud.vue";
import FilmCountCutCloud from "./home/elementvue/FilmCountCutCloud.vue";


export default {
  name: "FilmTranslation",
  data() {
    table:this.$store.state.currentPage
    return {
      //记录前一条数据，当前数据，和后一条数据
      previousID:"",
      nextID:"",
      idfilms:"",
      tableselected:this.$store.state.currentPage,
      make_sure_delete_but: false,
      make_sure_deleteclick: false,
      activeIndex: this.$store.state.current_active_index,
      perPage: 1,
      path: "",
      items: this.$store.state.current_select_item ? this.$store.state.current_select_item : 20,
      currentPage: parseInt(this.$store.state.current_page),
      total: 0,
      // 用于可视化组件传递属性名
      name_original: "name_original",
      transname_chi_mainland: "transname_chi_mainland",
      director_original: "director_original",
      actors_original: "actors_original",
      language_original: "language_original",
      film_type: "film_type",
      director_translation: "director_translation",
      translators: "translators",
      voice_actors: "voice_actors",
      agency_translation: "agency_translation",
      produce_time_original: "produce_time_original",
      release_time_mainland: "release_time_mainland",
      p_name: "p_name", // 译制工作者库
      film_name: "film_name",  // 电影小说库（下同）
      film_director: "film_director",
      novel_title: "novel_title",
      novel_author: "novel_author",
      novel_publish_company: "novel_publish_company",
      novel_release_time: "novel_release_time",
      // 用于搜索框选项
      currentSearchType: [],
      searchConfigs: {
        page1: [
          { value: '1', text: '电影原名' },
          { value: '2', text: '电影译名（大陆）' },
          { value: '3', text: '电影导演' },
          { value: '4', text: '电影演员' },
          { value: '5', text: '原片语种' },
          { value: '6', text: '原产国家' },
          { value: '7', text: '电影类型' },
          { value: '8', text: '译制导演' },
          { value: '9', text: '翻译译员' },
          { value: '10', text: '配音演员' },
          { value: '11', text: '翻译机构' },
          { value: '12', text: '发行时间' },
          { value: '13', text: '上映时间（大陆）' }
        ],
        page2: [
          { value: '1', text: '译制导演' },
          { value: '2', text: '翻译译员' },
          { value: '3', text: '配音演员' }
        ],
        page3: [
          { value: '1', text: '电影' },
          { value: '2', text: '导演' },
          { value: '3', text: '电影小说' },
          { value: '4', text: '作者/改编者' },
          { value: '5', text: '发表报刊/出版单位' },
          { value: '6', text: '发表/出版时间' },
        ],
      },
      selected: this.$store.state.current_select_state,
      select_input: '',
      payload: {},
      books: [],
      base_path: 'https://api.film.yingshinet.com/film',
      foreign_current_order: '当前：时间排序',
      china_current_order: '当前：默认排序',
      novel_current_order: '当前：时间排序'
    };
  },
  components: {
    CountrysMap,
    BarChart,
    DirectorsCloud,
    ActorsCloud,
    CountrysCloud,
    TitlesCloud,
    LanguagesCloud,
    DirTranslationCloud,
    TranslatorsCloud,
    VoiActorsCloud,
    TypeCloud,
    AgencyTransCloud,
    ProduceTimeCloud,
    ReleaseMainlandCloud,
    FilmCountCloud,
    FilmCountCutCloud,
  },
  methods: {
    movie_web(name){
      window.open("https://movie.yingshinet.com/#/directors?="+name.trim(),'_blank') // 在新窗口打开外链接
      // window.location.href = "https://movie.yingshinet.com/#/directors?="+name.trim();
    },
    foreign_old_order() {
      if(this.$store.state.current_path == this.base_path + 1){
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1_old")
        this.foreign_current_order = '当前：默认排序'
      }else {
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1")
        this.foreign_current_order = '当前：时间排序'
      }
      this.pageChange(this.currentPage)
    },
    china_old_order() {
      if(this.$store.state.current_path == this.base_path + 3){
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film3_new")
        this.china_current_order = '当前：时间排序'
      }else {
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film3")
        this.china_current_order = '当前：默认排序'
      }
      this.pageChange(this.currentPage)
    },
    novel_old_order() {
      if(this.$store.state.current_path == this.base_path + 6){
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film6_old")
        this.novel_current_order = '当前：默认排序'
      }else {
        this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film6")
        this.novel_current_order = '当前：时间排序'
      }
      this.pageChange(this.currentPage)
    },
    make_sure_delete(id){
      this.make_sure_delete_but=true
      this.idfilms=id
    },
    go_login() {
      this.$router.push("/login");
    },
    logout() {
      this.$store.commit('change_state_out')
      this.$message({
        message: '登出成功！',
        type: 'success'
      });
    },
    film_foreign_click() {
      this.$store.commit('change_current_film_page', "1");
      this.$store.commit('change_current_active_index', "1");
      this.$store.commit('change_previous_film_page', "1");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film1");
      this.foreign_current_order = '当前：时间排序';
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    film_nation_click() {
      this.$store.commit('change_current_film_page', "2");
      this.$store.commit('change_current_active_index', "2");
      this.$store.commit('change_previous_film_page', "2");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film2");
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    film_china_click() {
      this.$store.commit('change_current_film_page', "3");
      this.$store.commit('change_current_active_index', "3");
      this.$store.commit('change_previous_film_page', "3");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film3");
      this.china_current_order = '当前：默认排序';
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    film_help_click() {
      this.$store.commit('change_current_film_page', "4");
      this.$store.commit('change_previous_film_page', "4");
      this.$store.commit('change_current_active_index', "4");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film4");
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    film_translator_click() {
      this.$store.commit('change_current_film_page', "5");
      this.$store.commit('change_previous_film_page', "5");
      this.$store.commit('change_current_active_index', "5");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film5");
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    film_novel_click() {
      this.$store.commit('change_current_film_page', "6");
      this.$store.commit('change_previous_film_page', "6");
      this.$store.commit('change_current_active_index', "6");
      this.$store.commit('change_current_path', "https://api.film.yingshinet.com/film6");
      this.novel_current_order = '当前：时间排序';
      this.updateSearchConfig();
      this.pageChange(1);
      this.currentPage = 1;
    },
    updateSearchConfig() {
      switch (this.$store.state.current_film_page) {
        case "5":
          this.currentSearchType = this.searchConfigs.page2;
          this.selected = '1';
          this.$store.commit('change_current_select_state', "1");
          break;
        case "6":
          this.currentSearchType = this.searchConfigs.page3;
          this.selected = '3';
          this.$store.commit('change_current_select_state', "3");
          break;
        default:
          this.currentSearchType = this.searchConfigs.page1;
      }
    },
    reset() {
      this.$store.commit("change_current_select_content", JSON.stringify(null))
      this.$store.commit("change_current_select_text", "")
      this.$store.commit('change_current_state', "0")
      this.$store.commit('change_previous_film_page', "0")
      this.select_input = null
      this.pageChange(1)
      this.currentPage = 1
    },
    film_select() {
      this.currentPage = 1;
      this.$store.commit('change_current_page', 1);
      if (this.select_input !== "") {
        this.updatePayload();
        this.getQueryBooks();
      } else {
        this.reset();
      }
    },
    updatePayload() {
      const page = this.$store.state.current_film_page;
      const selectInput = this.select_input;
      let payload = {};

      if (page === '5') {
        payload = this.getPayloadForPage2(selectInput);
      } else if (page === '6') {
        payload = this.getPayloadForPage3(selectInput);
      } else {
        payload = this.getPayloadForPage1(selectInput);
      }

      this.payload = payload;
    },
    getPayloadForPage1(selectInput) {
      const payload = {};
      switch (this.selected) {
        case '1': payload.name_original = selectInput; break;
        case '2': payload.transname_chi_mainland = selectInput; break;
        case '3': payload.director_original = selectInput; break;
        case '4': payload.actors_original = selectInput; break;
        case '5': payload.language_original = selectInput; break;
        case '6': payload.country_produce = selectInput; break;
        case '7': payload.film_type = selectInput; break;
        case '8': payload.director_translation = selectInput; break;
        case '9': payload.translators = selectInput; break;
        case '10': payload.voice_actors = selectInput; break;
        case '11': payload.agency_translation = selectInput; break;
        case '12': payload.produce_time_original = selectInput; break;
        case '13': payload.release_time_mainland = selectInput; break;
      }
      this.$store.commit("change_current_select_state", this.selected);
      return payload;
    },
    getPayloadForPage2(selectInput) {
      const payload = { p_name: selectInput };
      this.$store.commit("change_current_select_state", this.selected);
      return payload;
    },
    getPayloadForPage3(selectInput) {
      const payload = {};
      switch (this.selected) {
        case '1': payload.film_name = selectInput; break;
        case '2': payload.film_director = selectInput; break;
        case '3': payload.novel_title = selectInput; break;
        case '4': payload.novel_author = selectInput; break;
        case '5': payload.novel_publish_company = selectInput; break;
        case '6': payload.novel_release_time = selectInput; break;
      }
      this.$store.commit("change_current_select_state", this.selected);
      return payload;
    },
    films(index,id) {
      this.$store.state.currentID = id;
      this.$store.state.index = index;
      this.$store.state.allbooks = this.books;
      this.$router.push({ path: '/intro', query: { film: id}, });
    },
    add_translation_film() {
      this.$router.push("/addUpdate");
    },
    add_translator_film() {
      this.$router.push("/addNewUpdate");
    },
    add_novel_film() {
      this.$router.push("/addNovelUpdate");
    },
    edit_film_translation(film) {
      this.$router.push({ path: "/addUpdate", query: { filmID: film.idfilms } });
    },
    edit_film_translator(film) {
      this.$router.push({ path: "/addNewUpdate", query: { filmID: film.idfilms } });
    },
    edit_film_novel(film) {
      this.$router.push({ path: "/addNovelUpdate", query: { filmID: film.idfilms } });
    },
    delete_film_translation() {
      const path ="https://api.film.yingshinet.com/film"+this.$store.state.current_film_page+"/delete"
      axios
        .post(path, {idfilms: this.idfilms})
        .then((response) => {
          if (response.data.msg == "delete success") {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            location.reload();
          } else {
            this.$message({
              message: "删除失败",
              type: "warning",
            });
          }
        });
    },
    getQueryBooks() {
      this.$store.commit("change_current_select_content", JSON.stringify(this.payload))
      this.$store.commit("change_current_select_text", this.select_input)
      this.$store.commit('change_current_state', "1")
      this.currentPage = parseInt(this.$store.state.current_page)
      this.pageChange(this.$store.state.current_page)
    },
    pageChange(page) {
      if (this.$store.state.current_state == "0") {
        this.$store.commit('change_current_page', page)
        if(this.$store.state.current_select_item != null){
          this.path = this.$store.state.current_path + "?page=" + page + "&offset=" + this.$store.state.current_select_item;
          
          // this.path = "https://api.film.yingshinet.com/film" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.$store.state.current_select_item;
          // console.log("select item: "+this.$store.state.current_select_item)
        }
        else{
          this.path = this.$store.state.current_path + "?page=" + page + "&offset=" + this.items;
          // this.path = "https://api.film.yingshinet.com/film" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.items;
          // console.log("select item: "+this.$store.state.current_select_item)
        }
        axios.get(this.path).then(res => {
          this.books = res.data.books;
          this.total = res.data.all_item_count
        }).catch(error => {
          console.error(error);
        });
      } else {
        this.$store.commit('change_current_page', page)
        if (this.$store.state.current_select_item != null) {
          this.path = "https://api.film.yingshinet.com/filmsquery" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.$store.state.current_select_item + "&divide_page=1";
        }
        else {
          this.path = "https://api.film.yingshinet.com/filmsquery" + this.$store.state.current_film_page + "?page=" + page + "&offset=" + this.items + "&divide_page=1";
        }
        axios.post(this.path, this.payload).then(res => {
          this.books = res.data.books
          this.total = res.data.all_item_count
        }).catch(error => {
          console.error(error);
        });
      }
    },
    sizeChange(items) {
      this.items = items;
      this.$store.commit('change_current_select_item', items);
      this.pageChange(this.currentPage);
    },
  },
  created() {
    this.$store.commit('change_current_page', this.$store.state.current_page);
    this.updateSearchConfig();
    if (this.$store.state.current_select_text == "" || this.$store.state.current_select_text == null) {
      console.log("换页");
      this.$store.commit('change_current_state', "0");
      this.pageChange(this.$store.state.current_page);
    } else {
      this.select_input = this.$store.state.current_select_text;
      this.payload = JSON.parse(this.$store.state.current_select_content);
      this.getQueryBooks();
    }
  },
};
</script>

<style>
.menu .el-menu-item {
  font-size: 16px;
}
.el-menu-item {
  padding: 0 0.5vw !important;
}
.el-menu--horizontal>.el-menu-item.is-active {
  font-size: 18px;
  border-bottom: rgba(255, 255, 255, 0) !important;
}
.film_name:hover {
  cursor: pointer;
}
.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}
.image_style:hover {
  z-index: 1;
  transform: scale(3, 3);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}
.table th,
.table td {
  vertical-align: middle;
}
.old_order:hover {
  background-color: aliceblue;
}
</style>
