import Vue from "vue";
import VueRouter from "vue-router";
import IntroFilm from "../components/IntroFilm.vue"
import Index from "../components/Index.vue"
import Home from "../components/Home.vue"
import FilmTranslation from "../components/FilmTranslation.vue"
import FilmMap from "../components/FilmMap.vue"
import AddUpdate from "../components/AddUpdate.vue"
import AddNewUpdate from "../components/AddNewUpdate.vue"
import AddNovelUpdate from "../components/AddNovelUpdate.vue"
import FilmsTime from "../components/FilmsTime.vue"
import FilmsTime2 from "../components/FilmsTime2.vue"
import Acknowledgement from "../components/Acknowledgement.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "books",
    component: Home,
    meta: {
      hide: true
    }
  },
  {
    path: "/intro",
    name: "IntroFilm",
    component: IntroFilm,
    meta:{
      hide: true
    }
  },
  {
    path: "/filmTranslation",
    name: "FilmTranslation",
    component: FilmTranslation,
  },
  //地图页面
  {
    path: "/filmMap",
    name: "FilmMap",
    component: FilmMap,
  },
  {
    path: "/addUpdate",
    name: "AddUpdate",
    component: AddUpdate,
  },
  {
    path: "/addNewUpdate",
    name: "AddNewUpdate",
    component: AddNewUpdate,
  },
  {
    path: "/addNovelUpdate",
    name: "AddNovelUpdate",
    component: AddNovelUpdate,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Index",
    component: Index,
    meta: {
      hide: true
    }
  },
  {
    path: "/acknowledgement",
    name: "Acknowledgement",
    component: Acknowledgement,
    meta: {
      hide: true
    }
  },
  {
    path: "/time",
    name: "FilmsTime",
    component: FilmsTime,
  },
  {
    path: "/time2",
    name: "FilmsTime2",
    component: FilmsTime2,
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition){
    //console.log(to)
    //console.log(from)
    //scrollBehavior方法接收to，form路由对象
    //第三个参数savedPosition当且仅当在浏览器前进后退按钮触发时才可用
    //该方法会返回滚动位置的对象信息，如果返回false，或者是一个空的对象，那么不会发生滚动
     if(savedPosition){
      setTimeout(() => {
        window.scrollTo(savedPosition.x, savedPosition.y)
        }, 300)
     }else{
      setTimeout(() => {
        window.scrollTo(0, 0)
     }, 300)
     }
 }
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { 
    // 判断该路由是否需要登录权限
    if (sessionStorage.getItem("token") == 'true') { 
      // 判断本地是否存在token
      next()
    } 
    else {
      // 本地不存在token
      // 未登录,跳转到登陆页面
      next({
        path: "/login"
      })
    }
  } 
  else {//不需要登录权限
    next();
  }
})

export default router;
