<template>
    <!-- 底部第一行容器 -->
    <div class="releaseMainland-Cloud" style="height: 20vh; width: 90vw; margin: 0 auto;">
        <svg id="releaseMainlandCloud" height="100%" />
    </div>
</template>
  
<script>
import * as d3 from 'd3'
import * as cloud from 'd3-cloud'
import * as $ from 'jQuery'
import { log } from 'console'

export default {
    components: {},
    name: 'releaseMainlandCloud',
    data() {
        return {
            words: [],
            path: 'https://api.film.yingshinet.com',
            releaseMainlandCloud: null,
            wordcount: 0,
            wordsdata: [],
            director: '',
            directors: [],
            figure_names: [],
            figures_serach: [],
            send_books: ""
        }
    },
    watch: {
        '$store.state.current_film_page': function () {
            //你需要执行的代码
            this.getEvents();
        }
    },

    methods: {
        //获得初始数据
        async getEvents() {
            const payload = {
                query_item: "release_time_mainland",
                table: ""
            };
            switch (this.$store.state.current_film_page) {
                case "1":
                    payload.table = "film_foreign"
                    break;
                case "2":
                    payload.table = "film2"
                    break;
                case "3":
                    payload.table = "film3"
                    break;
                default:
            }
            await this.$axios.post(this.path + '/film_count', payload).then((response) => {
                console.log(response)
                const count_length = response.data.length
                let wordsscale = d3.scalePow().exponent(0.3)
                this.words = []
                // 转成JSON格式
                for (let i = 0; i < count_length; i++) {
                    const figureItem = {}
                    figureItem.name = response.data.data[i][0]
                    figureItem.count = response.data.data[i][1]
                    this.words[i] = {
                        text: `${figureItem.name}`,
                        size: `${wordsscale(figureItem.count) * 5 + 2}`,
                    }
                }
                this.initReleaseMainlandCloud()
                console.log(this.words);
            })
        },
        // 给每个词加搜索功能
        initReleaseMainlandCloud() {
            var that = this;
            // 先根据this.words绘图
            this.drawReleaseMainlandCloud()
            // 绘图后根据添加的类名，给每个词添加搜索功能
            this.$nextTick(function () {
                document.querySelectorAll('.releaseMainland-word').forEach((item) => {
                    item.addEventListener('click', () => {
                        // 词云图词汇内容
                        let keyword = item.textContent
                        const payload = {
                            release_time_mainland: keyword,
                        };
                        that.send_books = keyword;
                        that.getQueryBooks(payload);
                    })
                })
            })
        },
        drawReleaseMainlandCloud() {
            let w = $('.releaseMainland-Cloud').width()
            let h = $('.releaseMainland-Cloud').height()
            let layout = cloud()
                .size([w, h])
                .words(this.words)
                .padding(3)
                .rotate(0)
                .font('Impact')
                .fontSize(function (d) {
                    return d.size
                })
                .on('end', draw)

            layout.start()

            function draw(words) {
                const color = d3.scaleOrdinal(d3.schemeCategory10)
                let svg = d3.select('#releaseMainlandCloud')
                svg.selectAll('*').remove()
                svg.attr('width', layout.size()[0]).attr('height', layout.size()[1])
                let g = svg
                    .append('g')
                    .attr('transform', 'translate(' + layout.size()[0] / 2 + ','
                            + layout.size()[1] / 2 + ')')
                let text = g
                    .selectAll('text')
                    .data(words)
                    .enter()
                    .append('a')
                    .attr('class', 'releaseMainland-word')
                    .append('text')
                    .attr('fill', (_, i) => color(i))
                    .attr('transform', function (d) {
                        return ('translate(' + [Math.random() * 1000 - 500,
                                Math.random() * 1000 - 500] +')')
                    })
                    .transition()
                    .style('font-size', function (d) {
                        return d.size + 'px'
                    })
                    .style('font-family', 'cloud3')
                    .attr('text-anchor', 'middle')
                    .text(function (d) {
                        return d.text
                    })
                    .attr('transform', function (d) {
                        return ('translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')' + 'scale(1.2)')
                    })
            }
        },
        getQueryBooks(payload) {
            this.$parent.select_input = this.send_books
            this.$parent.film_select()
            // const path = "https://api.film.yingshinet.com/filmsquery2";
            // // console.log("payload", payload)

            // this.$axios
            //   .post(path, payload)
            //   .then((res) => {
            //     this.send_books = res.data.books;
            //     if (this.send_books.length != 0) {
            //       this.$router.push({
            //         name: "FilmTranslation",
            //         params: { books: JSON.stringify(this.send_books) },
            //       });
            //     } else {
            //       alert("没有对应的内容！");
            //     }
            //   })
            //   .catch((error) => {
            //     console.error(error);
            //   });
        },
    },
    mounted() {
        // 导演影片数据词云获得初始数据
        this.getEvents()
    },
    watch: {
        "$store.state.current_film_page": {
            async handler(val, oldVal) {
                if (['1', '2'].includes(this.$store.state.current_film_page)) {
                    await this.getEvents();
                }
            },
        },
    },
}
</script>
  
<style scoped>
* {
    margin: 0;
    padding: 0;
    font-size: 0.7vw;
}

::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
}

.el-carousel__indicators li {
    border: 0.5vw;
}

.el-card__body {
    padding: 0.5vw;
}

.footerCard {
    margin: 0.5vw;
    margin-left: 0.5vw;
}
</style>
  
<style>
.releaseMainland-word:hover {
    cursor: pointer;
    text-decoration: none;
}

.footerCard .el-card__body {
    padding: 0.5vw;
}

a {
    text-decoration: none
}
</style>
