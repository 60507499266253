var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('router-view'),_c('div',{staticStyle:{"background-color":"#6C757D","margin-top":"2vw"}},[_vm._m(0),_c('div',{staticStyle:{"width":"1px","background-color":"#bbbbbb","height":"12vw","display":"inline-block","vertical-align":"top"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_c('div',{staticStyle:{"display":"inline-block","margin-left":"4vw","vertical-align":"top"}},[_vm._m(1),_c('div',{staticStyle:{"padding-top":"1vw","font-size":"1vw"}},[_c('div',{staticClass:"mouse_over",on:{"click":function($event){return _vm.gofilm()}}},[_vm._v("影片")])]),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","margin":"0 5vw","vertical-align":"top"}},[_c('div',[_c('a',{attrs:{"href":"http://www.cuc.edu.cn/","target":"_Blank"}},[_c('img',{staticStyle:{"height":"3.8vw","margin-top":"1.5vw"},attrs:{"src":require("./assets/cuclogo.png")}})])]),_c('div',{staticStyle:{"color":"#dbdbdb","padding-top":"1.5vw","font-size":"1.1vw"}},[_vm._v("©中国传媒大学 外国语言文化学院")]),_c('div',{staticStyle:{"color":"#dbdbdb","padding":"0.6vw 0 1.5vw 0","font-size":"1vw"}},[_vm._v("©国家社科重大项目：中国电影翻译通史-数据库")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"1.5vw","font-size":"1.2vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"href":"./#/home"}},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"1vw 0 0 0","font-size":"1vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"href":"./#/login"}},[_vm._v("个人中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","margin-left":"4vw","vertical-align":"top"}},[_c('div',{staticStyle:{"color":"#ffffff","padding-top":"1.5vw","font-size":"1.2vw"}},[_vm._v("相关链接")]),_c('div',{staticStyle:{"color":"#dbdbdb","padding":"1vw 0 0 0","font-size":"1vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"target":"_Blank","href":"http://sis.cuc.edu.cn/"}},[_vm._v("外国语言文化学院")])]),_c('div',{staticStyle:{"padding":"1vw 0 0 0","font-size":"1vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"href":"./#/acknowledgement"}},[_vm._v("致谢")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","margin-left":"4vw","vertical-align":"top"}},[_c('div',{staticStyle:{"color":"#ffffff","padding":"1.5vw 0 0 0","font-size":"1.2vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"target":"_Blank","href":"http://www.cuc.edu.cn/"}},[_vm._v("中国传媒大学")])]),_c('div',{staticStyle:{"color":"#dbdbdb","padding":"1vw 0 0 0","font-size":"1vw"}},[_c('a',{staticClass:"mouse_over",attrs:{"target":"_Blank","href":"http://ccs.cuc.edu.cn/"}},[_vm._v("计算机与网络空间安全学院")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","color":"#c5c5c5","margin-left":"4vw","padding-top":"2.2vw","position":"relative","font-size":"0.9vw"}},[_c('div',[_vm._v("技术支持：@中国传媒大学计算机与网络空间安全学院CBD实验室")])])
}]

export { render, staticRenderFns }