<template>
  <div style="width: 66vw; margin: 30px 0 0 17vw">
    <h3 align="center" style="color: #17a2b8">{{ title }}</h3>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <p style="font-size: 20px; color: #17a2b8">译制工作者信息</p>
      <el-form-item
        label="姓名"
        style="width: 70%; display: inline-block"
      >
        <el-input v-model="form.p_name"></el-input>
      </el-form-item>
      <el-form-item
        label="性别"
        style="width: 70%; display: inline-block"
      >
        <el-input v-model="form.p_sex"></el-input>
      </el-form-item>
      <el-form-item style="font-size: 1vw; display: inline-block; display: right">
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
      </el-form-item>
      <el-form-item label="照片">
        <div style="display: inline-block; vertical-align: top">
          <el-upload
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :limit="1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </div>
        <el-image
          v-if="title == '修改译制工作者信息' && form.filmImageFile"
          style="
            border-radius: 2%;
            display: inline-block;
            vertical-align: top;
            height: 150px;
            margin-left: 100px;
          "
          :preview-src-list="[form.filmImageFile]"
          class="update_image"
          :alt="1"
          :src="form.filmImageFile"
          >
        </el-image>
      </el-form-item>
      <el-form-item label="职业类型">
        <el-checkbox-group v-model="worktype">
          <el-checkbox label="译制导演"></el-checkbox>
          <el-checkbox label="配音演员"></el-checkbox>
          <el-checkbox label="翻译"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="人物小传">
        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" v-model="form.p_intro"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit('form')"
          >{{ submit }}</el-button
        >
        <el-button @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      rules: {
      },
      title: "",
      path: "",
      image_path: "",
      poster: null,
      worktype:[],
      form: {
        user_name: "",
        isactor:0,
        isdirector:0,
        istranslator:0,
        p_name: "",
        p_intro: "",
        p_sex: "",
        filmImageFile: "",
      },
      worktypes:['译制导演','配音演员','翻译'],
      dialogImageUrl: "",
      dialogVisible: false,
      loading: false,
      submit: "",
      add_update_type: 0,
    };
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.add_update_film();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    add_update_film() {
      this.loading = true;
      if(this.worktype.includes("译制导演")){
        this.form.isdirector=1
      }
      else{
        this.form.isdirector=0
      }
      if(this.worktype.includes("配音演员")){
        this.form.isactor=1
      }
      else{
        this.form.isactor=0
      }
      if(this.worktype.includes("翻译")){
        this.form.istranslator=1
      }
      else{
        this.form.istranslator=0
      }
      axios
        .post(this.path, this.form)
        .then((res) => {
          this.$notify({
            title: "提示",
            message: "信息添加成功！",
            type: "success",
          });
          if (this.poster == null) {
            this.$router.go(-1);
          } else {
            let file = this.poster;
            let poster_param = new FormData();
            let config = { headers: { "Content-Type": "multipart/form-data" } };
            poster_param.append("file", file);
            if (this.add_update_type) {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                this.$route.query.filmID;
            } else {
              this.image_path =
                "https://api.film.yingshinet.com/upload/film" +
                this.$store.state.current_film_page +
                "/" +
                res.data.id;
            }
            axios
              .post(this.image_path, poster_param, config)
              .then((response) => {
                if (response.data.status == 1) {
                  this.$notify({
                    title: "提示",
                    message: "照片上传成功！",
                    type: "success",
                  });
                  this.$router.go(-1);
                } else {
                  this.$notify({
                    title: "提示",
                    message: "照片上传失败！",
                    type: "warning",
                  });
                }
              });
          }
        })
        .catch((error) => {
          console.log(error)
          this.$notify({
            title: "提示",
            message: "信息上传失败（数据格式可能有问题）！",
            type: "warning",
          });
          this.loading = false;
        });
    },
    handleChange(file, fileList) {
      this.poster = file.raw;
    },
    handleRemove(file, fileList) {
      this.poster = null;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
  created() {
    if (this.$route.query.filmID != undefined) {
      axios
        .post(
          "https://api.film.yingshinet.com/filmsquery" +
            this.$store.state.current_film_page,
          { idfilms: this.$route.query.filmID }
        )
        .then((response) => {
          this.form = response.data.books[0];
          this.form.user_name = this.$store.state.current_user;
          if(response.data.books[0].isactor==1)
          this.worktype.push("配音演员")
          if(response.data.books[0].isdirector==1)
          this.worktype.push("译制导演")
          if(response.data.books[0].istranslator==1)
          this.worktype.push("翻译")
        });
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page +
        "/" +
        this.$route.query.filmID;
      this.title = "修改译制工作者信息";
      this.add_update_type = 1;
      this.submit = "修改";
    } else {
      console.log("修改 "+this.form)
      this.path =
        "https://api.film.yingshinet.com/film" +
        this.$store.state.current_film_page;
      this.title = "新增译制工作者信息";
      this.add_update_type = 0;
      this.submit = "新增";
    }
  },
};
</script>
<style>
.update_image:hover {
    cursor: pointer;
    transform: scale(1.2, 1.2);
    transition: 0.5s transform;
}
.el-form-item__label {
  font-size: 14px !important;
}

.el-button--primary {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner,
.el-radio__input.is-checked .el-radio__inner {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-radio__input.is-checked + .el-radio__label {
  color: #17a2b8 !important;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #17a2b8 !important;
}

.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #17a2b8 !important;
}

.el-month-table,
.el-year-table {
  font-size: 1vw;
}

.el-radio-button__inner,
.el-radio-group {
  vertical-align: baseline;
}
</style>
