<template>
  <!-- 底部第一行容器 -->
  <div style="height: 80vh; width: 100%;align:center;">
    <h1 style="font-size:30px; margin: 0 40% ">中国电影翻译史</h1>
    <span style="font-size:20px; margin: 0 40% ">外译库{{ count }}条</span>
    <svg id="time" height="100%" width="100%" />
    
  </div>
</template>

<script>
import * as d3 from 'd3'
import * as cloud from 'd3-cloud'
import * as $ from 'jQuery'
import { log } from 'console'


export default {
  components: {},
  name: 'countrysCloud',
  data() {
    return {
      path: 'https://api.film.yingshinet.com',
      countyear1: '125',
      selectvalue: 0,
      dataset: [],
      count: 0,
      currentPage: 3
    }
  },

  methods: {
    drawTimeGraph() {
      let width = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) * 0.98;
      let height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 1.2;
      const fill = d3.scaleOrdinal(d3.schemeCategory10);
      let yeardata = new Array(26);
      for (let i = 0; i < 26; i++) {
        yeardata[i] = i * 5;
      }
      // 提示框部分
      let tooltip = d3.selectAll("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity",0.0);
      let pic = d3.selectAll("pic")
        .append("image")
        .attr("class", "pic")
        .style("opacity", 0.0);
      let svg = d3.select("#time")			//选择<body>
        .append("svg")			//在<body>中添加<svg>
        .attr("width", width)	//设定<svg>的宽度属性
        .attr("height", height);//设定<svg>的高度属性
      //矩形所占的宽度（包括空白），单位为像素
      let rectStep = width / this.countyear1;
      //矩形所占的宽度（不包括空白），单位为像素
      let img_h = 60;
      let img_w = 50;
      let radius = 5;
      let rectWidth = rectStep - 2;
      let j = 0;
      let imgs = svg.selectAll(".img")
        .data(this.dataset)
        .enter()
        .append("rect")
        .attr("class", "img")
        .attr("x", function (d, i) {
          return (d.produce_time_original.substring(0,4) - 1900) * (width / 26 / 5) + 40 - radius;
        })
        .attr("y", function (d, i) {
          if ((i > 0) && (d.produce_time_others.substring(0,4) == d.produce_time_original.substring(0,4))) {
            let y = height / 2 - (j + 1) % (d.produce_time_original.substring(0,4) - 1900) * 2 * radius - 53 + 90;
            j = j + 1;
            return y;
          }
          else {
            j = 0;
            return height / 2 - j % (d.produce_time_original.substring(0,4)-1900) * 2 * radius - 53 + 90;
          }
        })
        .attr("width", radius * 2)
        .attr("height", radius * 2)
        .style("stroke", "none")
        .style("stroke-width", "0.5px")
        .attr("fill", function (d, i) {
          //创建圆形图片
          let defs = svg.append("defs").attr("id", "imgdefs");
          let catpattern = defs.append("pattern")
            .attr("id", "catpattern" + i)
            .attr("height", 1)
            .attr("width", 1);
          catpattern.append("image")
            .attr("x", - (img_w / 2 - radius + 5.8))
            .attr("y", - (img_h / 2 - radius + 3.5))
            .attr("width", img_w + 11)
            .attr("height", img_h + 6)
            .attr("xlink:href", "https://assets.yingshinet.com/chinese_film_translation/film3small/" + d.filmImageFile);
          return "url(#catpattern" + i + ")";
        })
        .on("mouseover", function (d, i) {
          tooltip.html(d.name_original + "-" + d.transname_chi_mainland + "</br>" + "出品年份：" + d.produce_time_original + "</br>" + "导演：" + (d.director_original))
            .style("left", (event.pageX) + "px")
            .style("top", (event.pageY + 20) + "px")
            .style("opacity", 1.0);
          d3.select(this)
            .style("cursor", "pointer");
          pic = svg.append("image")
            .attr("x", 50)
            .attr("y", 0)
            .attr("width", img_w * 3)
            .attr("height", img_h * 3)
            .attr("xlink:href", "https://assets.yingshinet.com/chinese_film_translation/film3/" + d.filmImageFile)
            .style("opacity", 1.0);
        })
        .on("mousemove", function (d) {
            tooltip.style("left", (event.pageX) + "px")
            .style("top", (event.pageY + 20) + "px");
        })
        .on("click", function (d) {
          window.location.hash = 'intro?film=' + d.idfilms;
          window.location.reload();
        })
        .on("mouseout", function (d) {
          tooltip.style("opacity", 0.0);
          pic.style("opacity", 0.0);
        });

      svg.append("line")
        .attr("x1", 20)
        .attr("y1", height / 2 + 50)
        .attr("x2", width - 20)
        .attr("y2", height / 2 + 50)
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("opacity", 1);
      let year = svg.selectAll(".year")
        .data(yeardata)			//绑定数据
        .enter()				//获取enter部分
        .append("text")			//添加text元素，使其与绑定数组的长度一致
        .attr("class", "year")
        .attr("fill", "black")
        .attr("stroke", "none")
        .attr("font-size", "20px")
        .attr("font-family", "宋体")
        .attr("text-anchor", "middle")
        .attr("x", function (d, i) {
          return width / 26 / 5 * d;
        })
        .attr("y", height / 2 + 90)
        .attr("dx", "40")
        .attr("dy", "-1em")
        .text(function (d, i) {
          return d + 1900;
        });
    },
    //获得初始数据
    choose(row) {
      d3.selectAll("svg > *").remove();
      this.getEvents()
    },
    async getEvents() {
      await this.$axios.post(this.path + '/filmpic2',{}).then((response) => {
        this.dataset = response.data.data;
        this.count = response.data.count;
        console.log(this.count)
        for (let i = 1; i < this.count; i++) {
          this.dataset[i].produce_time_others = this.dataset[i - 1].produce_time_original;
        }
        this.drawTimeGraph()
      })
    }
  },
  mounted() {
      this.$store.commit('change_current_film_page', "3")
            this.$store.commit('change_state_in', "1")
    // 获得初始数据
    this.getEvents()

  },
}


</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}

.el-card__body {
  padding: 0.5vw;
}

.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.countrys-word:hover {
  cursor: pointer;
  text-decoration: none;
}

.footerCard .el-card__body {
  padding: 0.5vw;
}
</style>

<style>
.tooltip{
	position: absolute;
	font-family: 宋体;
	background-color: #d0dfe6d1;
}
</style>
